import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';


//MUI
// import { makeStyles } from '@material-ui/core/styles';
import { Container,  Link } from '@material-ui/core';

//ICON
// import { LuScrollText } from "react-icons/lu";
// import { IoChatbubblesOutline } from "react-icons/io5";
import { FiChevronRight, FiFileText, FiPhone } from "react-icons/fi";



//COMPONENTS or LAYOUT
import TitleBar from '@layouts/TitleBar';


export default function HelpCenter() {
    const { t } = useTranslation();

    const { accessToken} = useSelector(state => state.general);
    // const theme = useTheme();
    // const styles = useStyles();




    return (

        <div>
            <TitleBar back btnRegister backUrl="/settings" spendButton displayUsername />

            <Container>
                <div className='p-b-20'>
                    <p className='fs-title cl-theme'><b>{t('support.support')}</b></p>
                </div>

                <div>
                    <Link underline='none' to="/termOfUse" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiFileText className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('support.termOfUse')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>
                    <div className='underline-white w-full' style={{ height: 4 }}></div>

                    <Link underline='none' to="/refundAndReturnAgreement" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiFileText className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('support.refundAgreement')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <Link underline='none' to="/privacyAgreement" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiFileText className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('support.privacyAgreement')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <Link underline='none' to="/merchantServiceAgreement" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiFileText className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('support.merchantServicesAgreement')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>

                    <Link underline='none' to="/shippingPolicy" component={RouterLink} >
                        <div className='flex-sb-m w-full p-all-15'>
                            <div className='flex-m'>
                                <FiFileText className='fs-icon-small'/>
                                <p className='cl-theme p-l-15 fs-content'>{t('support.shippingPolicy')}</p>
                            </div>
                            <FiChevronRight />
                        </div>
                    </Link>
                </div>

                <Link underline='none' to="/contactUs" component={RouterLink} >
                    <div className='flex-sb-m w-full p-all-15'>
                        <div className='flex-m'>
                            <FiPhone className='fs-icon-small'/>
                            <p className='cl-theme p-l-15 fs-content'>{t('support.contactUs')}</p>
                        </div>
                        <FiChevronRight />
                    </div>
                </Link>
            </Container>
        </div>
    );
}


// const useStyles = makeStyles(theme => ({



// }));


