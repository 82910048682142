import axios from 'axios';
import jwt_decode from "jwt-decode";
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { authFail, authSuccess, changeLanguage as reduxChangeLanguage } from '@actions';
import { BACKEND_URL, CLIENT_SECRET } from '@configs/Config';
import { getUrl, logoutUser } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, Link, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// ICON
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// COMPONENTS & LAYOUTS
import WebpImg from '@layouts/WebpImg';
import TitleBar from '@layouts/TitleBar';

export default function Login() {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { accessToken, shareLinkProductId } = useSelector(state => state.general);
    const { addAlert, setLoading } = useNotificationLoading();
    let history = useHistory();
    const styles = useStyles();
    const query = useQuery();
    const source = query.get('src');
    // const { name } = useSelector(state => state.user);

    const [state, setState] = useState({
        showPassword: false,
        username: '',
        password: ''
    });
    const [userLogin, setUserLogin] = useState(false);

    useEffect(() => {
        if (accessToken) {
            // if (source) {
            //     if (userLogin) {
            //         // user login, redirect to third party page
            //         setLoading(true);
            //         getUrl(`/login-to/${source}`).then(response => {
            //             setLoading(false);
            //             if (response.status === 1 && response.url) {
            //                 window.location.href = response.url;
            //             } else {
            //                 addAlert(t('general.loginRedirectError'));
            //             }
            //         }).catch(error => {
            //             setLoading(false);
            //             addAlert(JSON.stringify(error.message));
            //         });
            //     } else {
            //         // user already login, ask continue as XXX
            //         setContinueDialogOpen(true);
            //     }
            // } else {
                let previousUrl = "";
                if (document.referrer) previousUrl = new URL(document.referrer);
                if (shareLinkProductId) {
                    history.replace({ pathname: `product/${shareLinkProductId}` });
                } else if (!previousUrl || (previousUrl && window.location.host != previousUrl['host'])) {
                    history.replace({ pathname: "/" });
                } else {
                    history.goBack();
                }
            // }
        }
    }, [accessToken, history, shareLinkProductId, source]);

    const loginUser = () => {
        setLoading(true);
        setUserLogin(true);

        const loginState = {
            grant_type: 'password',
            client_id: '4',
            client_secret: CLIENT_SECRET,
            username: state.username,
            password: state.password,
            scope: '*',
        }
        if (state?.username && state?.password){
            axios.defaults.headers.common['Accept'] = 'application/json';
            axios.post(`${BACKEND_URL}/oauth/token`, loginState)
                .then((response) => {
                    // setLoading(false);
                    setLoading(false);
                    if (response.status === 200) {
                        // dispatch(authSuccess(response.data));
                        // history.goBack();
                        const decoded_jwt = jwt_decode(response.data.access_token);
                        const userRole = decoded_jwt ? decoded_jwt.role : [];
                        if (_.size(_.intersection(userRole, ['member']))) {
                            dispatch(authSuccess(response.data));
                        } else {
                            addAlert(t('general.incorrectUsernamePassword'));
                        }
                    }
                }).catch((error) => {
                    console.log("error", error);
                    setLoading(false);
                    if (error.response && error.response.status === 400) {
                        addAlert(t('general.incorrectUsernamePassword'));
                        // console.log('The username or password is incorrect');
                    } else if (error.response && error.response.status === 401) {
                        let errorResponse = JSON.parse(error.request.response);
                        addAlert(errorResponse.message);
                    } else {
                        addAlert(JSON.stringify(error.message));
                        // console.log('Please try again later or contact Technical Support if problem persist.');
                    }
                    dispatch(authFail());
                    // setLoading(false);            
                });
        }
        else
        {
            setLoading(false);
            addAlert(t('general.incorrectUsernamePassword'));
        }
        
    }

    // const [continueDialogOpen, setContinueDialogOpen] = useState(false);
    // const dontContinueAs = () => {
    //     setContinueDialogOpen(false);
    //     logoutUser();
    // }
    // const continueAs = () => {
    //     setContinueDialogOpen(false);
    //     setLoading(true);
    //     getUrl(`/login-to/${source}`).then(response => {
    //         setLoading(false);
    //         if (response.status === 1 && response.url) {
    //             window.location.href = response.url;
    //         } else {
    //             addAlert(t('general.loginRedirectError'));
    //         }
    //     }).catch(error => {
    //         setLoading(false);
    //         addAlert(JSON.stringify(error.message));
    //     });
    // }

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(reduxChangeLanguage(lang));
    };

    return (
        <div className='min-h-100vh'>
        <TitleBar back backgroundColor="bg-theme" loginButton={false} />
            <div className='bg-theme p-b-30 header-radius'>
                <div className='p-t-30'>
                    <center><img src="/images/logo/logo-fit-white.png" style={{ width: 150, maxWidth: '50%' }} alt="logo" /></center>
                    <p className='txt-center txt-upper fs-header clwhite p-t-15'>{t('title.login')}</p>
                </div>
            </div>
            <Container className='p-t-40'>
                <TextField
                    label={t('register.username')}
                    value={state.username}
                    InputProps={{
                        startAdornment: <InputAdornment position="start"><PersonIcon /></InputAdornment>,
                    }}
                    variant="filled"
                    autoComplete="email"
                    color='primary'
                    fullWidth={true}
                    onChange={({ target }) => setState({ ...state, username: target.value })}
                />
                <TextField
                    style={{ marginTop: 20 }}
                    label={t('register.password')}
                    type={state.showPassword ? 'text' : 'password'}
                    value={state.password}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">
                            <LockIcon />
                        </InputAdornment>,
                        endAdornment: <InputAdornment position="end">
                            <IconButton onClick={() => setState({ ...state, showPassword: !state.showPassword })} onMouseDown={(event) => event.preventDefault()} edge="end">
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    variant="filled"
                    color='primary'
                    fullWidth={true}
                    onChange={({ target }) => setState({ ...state, password: target.value })}
                />
                <Box className={styles.paddingVertical10}>
                    <Link underline='none' to={`/forgot-password`} component={RouterLink}>
                        <p className='fs-content txt-right cl-text'>
                            {t('title.forgotPassword')}
                        </p>
                    </Link>
                </Box>
                <Box className={styles.paddingVertical10}>
                    <Button variant="contained" fullWidth color="secondary" size="large" style={{ borderRadius: 48 }} onClick={loginUser}>
                        {t('button.login')}
                    </Button>
                </Box>
                {/* <Box className={styles.paddingVertical10} display="flex" justifyContent="center">
                    <Link underline='none' onClick={() => changeLanguage('en')} component={RouterLink} style={{ marginRight: 5 }} to={`/login`} >
                        <Typography>{t('Eng')}</Typography>
                    </Link>
                    <Typography>{t('|')}</Typography>
                    <Link underline='none' onClick={() => changeLanguage('cn')} component={RouterLink} style={{ marginLeft: 5 }} to={`/login`}>
                        <Typography>{t('中文')}</Typography>
                    </Link>
                </Box> */}
            </Container>
            {/* <Dialog open={continueDialogOpen} >
                <DialogTitle id="scroll-dialog-title">{t('login.continueAsTitle', { 'name': name })}</DialogTitle>
                <DialogContent dividers={false}>{t('login.continueAsContent', { 'source': source })}</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => continueAs()} color="primary">{t("login.continueYes")}</Button>
                    <Button variant="contained" onClick={() => dontContinueAs()} color="primary">{t("login.continueNo")}</Button>
                </DialogActions>
            </Dialog> */}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    paddingVertical10: {
        paddingTop: 10,
        paddingBottom: 10,
    },
}));