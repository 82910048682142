import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getUrl } from '@helper/ApiAction';
import { useQuery } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import ProductItemCard from '@components/ProductItemCard';
import TitleBar from '@layouts/TitleBar';


export default function Vouchers() {
    const styles = useStyles();
    
    const isMountedRef = useRef(null);
    let key = useQuery();
    const keyword = key.get("s");
    
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { currency, currencyDisplay } = useSelector(state => state.general);

    const [voucherProducts, setVoucherProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(1);

    // ----------------- APIs ------------------------

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        getUrl(`product/vouchers?page=${page}`).then(response => {
            if (response) {
                let { current_page, last_page } = response.data.meta;
                setVoucherProducts([...voucherProducts, ...response.data.data]);
                setTotal(last_page);
                setPage(current_page ? current_page : 1);
                setLoading(false);
            }
            setLoading(false);
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, page, keyword]);

    // ------------- Functions ----------------

    const loadProducts = () => {
        setPage(page + 1);
    }

    // ---------------- Variables ---------------
    let currency_code = [];
    _.map(currencyDisplay,(currency_detail)=>{
        if(currency_detail.code == currency){
            currency_code = currency_detail.desc.split('|');
        }
    });

    return (
        <Box className={styles.root2} style={{ backgroundImage:'url(/images/general/bkg/gradient.png)', backgroundSize:'cover', backgroundRepeat:'repeat-y', backgroundPosition:'center center' , minHeight:'100vh'}}>
            <TitleBar height={70} title={t('item.products')} displayInfo displayCart back pagetitle spendButton backUrl="/" />
            <Box>
                <Container className={styles.root}>
                    {
                        voucherProducts.length > 0
                            ? <div style={{ padding: '0 20px' }}>
                                <Grid container spacing={1}>
                                    {
                                        _.map(voucherProducts, (product) => {
                                            return (
                                                <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                                    <ProductItemCard product={product} currencyCode={currency_code} />
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>

                                <div className='p-t-30'>
                                    {
                                        page < total ?
                                            <Button onClick={loadProducts} fullWidth>{t('transaction.loadMore')}</Button>
                                            : <Typography variant='body2' className='txt-center'>{t('transaction.endOfList')}</Typography>
                                    }
                                </div>
                                {/*  */}
                            </div>
                            : <div className='txt-center'>
                                <img src='/images/empty/product.png' alt="noimage" style={{ width: 120 }} />
                                <Typography variant="body1" style={{ textAlign: "center" }}>{t('general.noProduct')}</Typography>
                            </div>
                    }
                </Container>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px',
    },
    cardContentRootStyle: {
        padding: '10px !important',
        display: 'flex',
        alignItems: 'flex-end',
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
}));
