import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import _ from 'lodash';

//MUI
import { CircularProgress, Checkbox, FormControl, Input, InputAdornment, Container,  Button,  Typography, Grid, Dialog, DialogActions, DialogContent, Link, Box, FormControlLabel } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//ICON
// import StorefrontIcon from '@material-ui/icons/Storefront';
import { Circle } from 'react-feather';
import { FaCheck } from "react-icons/fa6";
import { IoMdTrash } from "react-icons/io";
import { LuTrash } from "react-icons/lu";
import { FiMinus, FiPlus, FiAlertTriangle } from "react-icons/fi";


// OTHERS
import { getUrl, deleteUrl, putUrl } from '@helper/ApiAction';
import { updateCartTotal } from '@actions';
import { currencyFormat } from '@helper/Tools';
import useNotificationLoading from '@helper/useNotificationLoading';
import Alert from '@material-ui/lab/Alert';

//COMPONENTS & LAYOUTS
import WebpImg from '@layouts/WebpImg';
import TitleBar from '@layouts/TitleBar';

export default function Cart() {
    const theme = useTheme();
    const styles = useStyles();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    let history = useHistory();

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { accessToken, cartTotal } = useSelector(state => state.general);
    const { rank: user_rank } = useSelector(state => state.user);
    const { currency, currencyRate, currencyDisplay } = useSelector(state => state.general);

    const [state, setState] = useState({
        checkAll: false,
        dialogShow: false,
        removeCartId: [],
        totalSelected: 0,
        totalPrice: 0,
        totalWalletPrice: {},
        open: false,
        error: false,
        message: '',
    });
    const [cart, setCart] = useState(null);
    const [currencyData, setCurrencyData] = useState(null);
    const [wallets, setWallets] = useState([]);

    let currency_code = [];
    _.map(currencyDisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ---------- APIs ----------
    useEffect(() => {
        getUrl('wallets').then(response => {
            if (isMountedRef.current && response.status === 1) {
                setWallets(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        setLoading(true);
        isMountedRef.current = true;
        if (isMountedRef.current) {
            setLoading(false);
            getUrl('carts').then(cartList => {
                setCurrencyData(cartList.currency_data);
                setCart(cartList.data);
                calculateTotal(cartList.data);
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [wallets, addAlert]);

    // ---------- Functions ----------
    const calculateTotal = userCart => {
        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};

        let checkAll = true;
        _.map(userCart, merchantItem => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        // let starter = (user_rank && cartItem.product.rank) ? ((user_rank === null || cartItem.product.rank.code > user_rank.code) ? true : false) : true;
                        // let itemPrice = cartItem.product.type === 'rank' ? (starter ? cartItem.product.price_starter : cartItem.product.price_repurchase) : cartItem.product.sell_price;
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = cartItem.product.sell_price;
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        // let priceMod = cartItem.product.type === 'rank' ? (starter ? pricingOption.price_mod_starter : pricingOption.price_mod_repurchase) : pricingOption.price_mod;
                                        let priceMod = pricingOption.price_mod;
                                        itemPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : itemPrice;
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                            // const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                            // totalPrice += parseFloat((itemPrice * selectedPricing.cash_scale * cartItem.quantity).toFixed(2));
                            // if(selectedPricing.multi_wallet_type === 'separate'){
                            //     _.map(selectedPricing.multi_wallet_array, (data, key) => {
                            //         _.map(wallets, (w, key) => {
                            //             if(parseInt(data.id) === parseInt(w.id)){
                            //                 let name = _.split(w.name, '|');
                            //                 let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                            //                 if(display_name){
                            //                     const old_amount = parseFloat(totalWalletPrice[display_name] ? totalWalletPrice[display_name] : 0);
                            //                     totalWalletPrice[display_name] = (old_amount + (parseFloat(data.amount) * cartItem.quantity)).toFixed(selectedPricing.decimal);
                            //                 }
                            //             }
                            //         })
                            //     })
                            // }else{
                            //     if(parseFloat(selectedPricing.wallet_amount) > 0){
                            //         let name = [];
                            //         if(selectedPricing.multi_wallet_type === 'combine'){
                            //             name = _.split(selectedPricing.pricing_name, '|');
                            //         }else if(selectedPricing.multi_wallet_type === 'single'){
                            //             name = _.split(selectedPricing.wallet_name, '|');
                            //         }
                            //         let display_name = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                            //         if(display_name){
                            //             const old_amount = parseFloat(totalWalletPrice[display_name] ? totalWalletPrice[display_name] : 0);
                            //             totalWalletPrice[display_name] = (old_amount + (parseFloat(selectedPricing.wallet_amount) > 0 ? (parseFloat(selectedPricing.wallet_amount) * cartItem.quantity) : 0)).toFixed(selectedPricing.decimal);
                            //         }
                            //     }
                            // }
                        } else {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        }
                    } else if (shippingChannel !== 'unlisted') {
                        checkAll = false;
                    }
                })
            });
        })
        setState(state => ({ ...state, totalPrice, totalSelected, checkAll, totalWalletPrice }));
    };

    const checkAll = () => {
        const newCheckAll = state.checkAll ? false : true;
        const newCart = cart;
        _.map(newCart, (merchantItem, merchantIndex) => {
            _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                _.map(cartDetails, (cartItem, cartIndex) => {
                    if (shippingChannel !== 'unlisted') {
                        if (Boolean(cartItem.selected) !== Boolean(newCheckAll)) {
                            putUrl(`carts/${cartItem.id}`, {
                                selected: newCheckAll ? 1 : 0,
                                quantity: cartItem.quantity,
                            }).catch(error => {
                                addAlert(JSON.stringify(error.message));
                            });
                        }

                        newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                            ...cartItem, quantity: cartItem.quantity, selected: newCheckAll ? 1 : 0
                        }
                    }
                })
            })
        })
        setCart(newCart);
        setState({ ...state, checkAll: newCheckAll });
        calculateTotal(newCart);
    }

    const removeCart = async () => {
        const { removeCartId } = state;
        if (removeCartId) {
            const cartIdString = _.join(removeCartId, ',');
            deleteUrl(`carts/${cartIdString}`).then(response => {
                if (response.status === 0) {

                } else {
                    const newCart = cart;
                    _.map(newCart, (merchantItem, merchantIndex) => {
                        let merchantProduct = false;
                        _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                            newCart[merchantIndex]['items'][shippingChannel] = _.remove(cartDetails, cartItem => {
                                if (!_.includes(removeCartId, cartItem.id)) {
                                    return cartItem;
                                }
                            })
                            if (_.size(newCart[merchantIndex]['items'][shippingChannel]) !== 0) {
                                merchantProduct = true;
                            }
                        })
                        if (!merchantProduct) {
                            delete newCart[merchantIndex];
                        }
                    })
                    dispatch(updateCartTotal(cartTotal - 1));
                    setCart(newCart);
                    setState({ ...state, dialogShow: false, removeCartId: [] });
                    calculateTotal(newCart);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const updateCart = (cartId, inputQuantity, reverseSelected) => {
        let selectedCartItem = {};
        _.map(cart, merchantItem => {
            _.map(merchantItem.items, cartDetails => {
                const matchItem = _.find(cartDetails, ['id', cartId]);
                if (_.size(matchItem) > 0) {
                    selectedCartItem = matchItem;
                }
            })
        })
        let newInputQuantity = selectedCartItem.quantity;
        let newInputSelected = selectedCartItem.selected;
        if (inputQuantity) {
            newInputQuantity = parseInt(inputQuantity) > 0 ? parseInt(inputQuantity) : 1;
        }
        if (reverseSelected) {
            newInputSelected = newInputSelected ? false : true;
        }

        let newCart = cart;
        putUrl(`carts/${cartId}`, {
            selected: newInputSelected ? 1 : 0,
            quantity: newInputQuantity,
        }).then(response => {
            if (response.status === 0) {
                addAlert(_.map(response.error.cart).join(' '));
            } else {
                _.map(newCart, (merchantItem, merchantIndex) => {
                    _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                        _.map(cartDetails, (cartItem, cartIndex) => {
                            if (cartItem.id === cartId) {
                                newCart[merchantIndex]['items'][shippingChannel][cartIndex] = {
                                    ...cartItem, quantity: newInputQuantity, selected: newInputSelected
                                }
                            }
                        })
                    });
                })
                setCart(newCart);
                calculateTotal(newCart);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    const checkoutCart = () => {
        if (!accessToken) {
            // return setState(state => ({ ...state, open: true, error: true, message: t('cart.loginToProceed') }));
            return addAlert(t('cart.loginToProceed'));
        }
        let proceedCheckout = true;
        if (_.size(cart) > 0) {
            _.map(cart, merchantItem => {
                _.map(merchantItem.items, cartDetails => {
                    _.map(cartDetails, cartItem => {
                        if (cartItem.selected) {
                            let stockAvailable = 0;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (option) => {
                                    _.map(option.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            stockAvailable = pricingOption.quantity;
                                        }
                                    })
                                });
                            } else {
                                stockAvailable = cartItem.product.quantity;
                            }

                            if (cartItem.quantity > stockAvailable) {
                                proceedCheckout = false;
                                addAlert(t('general.stockExceedError'));
                            }
                        }
                    })
                })
            })
        }

        if (proceedCheckout) {
            return history.push("/checkout");
        }
    }

    const priceDisplay = (cartItem, unlisted, display) => {
        // unlisted: true/false
        // display: web/mobile
        // let starter = (user_rank && cartItem.product.rank) ? ((user_rank === null || cartItem.product.rank.code > user_rank.code) ? true : false) : true;
        // let productPrice = cartItem.product.type === 'rank' ? (starter ? cartItem.product.price_starter : cartItem.product.price_repurchase) : cartItem.product.sell_price;
        let productPrice = cartItem.product.sell_price;
        let priceStyle = {};
        let noMultiPricing = true;
        if (_.size(cartItem.product.options) > 0) {
            _.map(cartItem.product.options, (optionItem) => {
                _.map(optionItem.pricing, (pricingOption) => {
                    if (pricingOption.id === cartItem.product_warehouse_id) {
                        // let priceMod = cartItem.product.type === 'rank' ? (starter ? pricingOption.price_mod_starter : pricingOption.price_mod_repurchase) : pricingOption.price_mod;
                        let priceMod = pricingOption.price_mod;
                        productPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : productPrice;
                    }
                })
            })
        }
        if (display === 'mobile' && unlisted === false) {
            priceStyle = { color: theme.palette.price.main, fontSize: 10 };
        } else if (display === 'mobile' && unlisted === true) {
            priceStyle = { color: "#808080", fontSize: 10 };
        } else if (display === 'web' && unlisted === false) {
            priceStyle = { color: theme.palette.price.main };
        } else if (display === 'web' && unlisted === true) {
            priceStyle = { color: "#808080" };
        }
        return (
            <>
                {
                    (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) ?
                        _.map(cartItem.product.multi_pricings, (pricingItem, pricingIndex) => {
                            if (pricingItem.id === parseInt(cartItem.multi_pricing_id)) {
                                noMultiPricing = false;
                                let pricingText = [];
                                _.map(pricingItem.wallet_settings, (setting, key) => {
                                    let minAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.min_amount / 100) : setting.min_amount);
                                    let maxAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.max_amount / 100) : setting.max_amount);
                                    pricingText[key] = { 'prefix': `${setting.pricing_name} `, 'min_amount': parseFloat(minAmount * cartItem.quantity).toFixed(pricingItem.decimal), 'max_amount': parseFloat(maxAmount * cartItem.quantity).toFixed(pricingItem.decimal) };
                                })
                                // if ((cartItem.product.type === 'rank' && starter && pricingItem.type !== 'starter') ||
                                //     (cartItem.product.type === 'rank' && !starter && pricingItem.type !== 'repurchase') ||
                                //     (cartItem.product.type === 'normal' && pricingItem.type !== 'normal')) {
                                //     noMultiPricing = true;
                                // }
                                return (
                                    <div key={pricingIndex} className='flex-col' style={unlisted === true ? { color: "#808080" } : {}}>
                                        <p className='txt-title p-t-10' style={{ fontSize: 10 }}>{t('payment.paymentMethod')}:<br /></p>
                                        {
                                            _.map(pricingText, (data, key) => {
                                                return (
                                                    <p className='txt-title' key={key} style={{ fontSize: 10 }}>
                                                        {data.prefix}
                                                        (
                                                            <NumberFormat value={data.min_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                            {
                                                                data.min_amount !== data.max_amount
                                                                    ? <NumberFormat value={data.max_amount} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={' - '} />
                                                                    : null
                                                            }
                                                        )
                                                    </p>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        })
                        : <p className='txt-title p-t-10' style={{ fontSize: 10 }}>
                            {t('payment.paymentMethod')}:<br />
                            {t('product.onlineBanking')}
                        </p>
                        // <p className='fs-16 cl-theme'>
                        //     <b><NumberFormat value={(cartItem.quantity * productPrice * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></b>
                        // </p>
                }

                {(_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) && noMultiPricing ?
                    <p className='fs-14 cl-label'>
                        {t('cart.paymentInvalid')}
                    </p>
                    : null
                }

            </>
        )
    }

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" back  currencyButton spendButton loginButton={false} />

            <div className='bg-theme p-b-100 header-radius'>
                <Container>
                
                    <div className='flex-c-m w-full'>
                    {
                        !accessToken &&
                        <FiAlertTriangle className='fs-18 clwhite m-r-10' />
                    }
                        <p className='txt-center fs-17 clwhite'>{t('title.cart')}</p>
                    </div>
                    <div className='subtitle-box'>
                    {
                        accessToken ?
                        <>
                        {
                            cartTotal > 0
                                ? <p className='cl-label fs-14 lh-11 txt-center'>{t('cart.subtitleCart')}</p>
                                : <p className='cl-label fs-14 lh-11 txt-center'>{t('cart.subtitleEmpty')}</p>
                        }
                        </>
                        :
                                <div className='p-t-10'>
                                    <div className='w-full'>
                                        <p className='p-l-10 cl-label fs-14 lh-11 txt-center'>{t('cart.noLogin')}</p>
                                    </div>
                                    <div className='p-t-15 w-full'>
                                        {/* <Link underline='none' color="inherit" to="/register" component={RouterLink} style={{ "marginRight": 10 }}>
                                            <div className='btn-white fs-14 translateY' style={{ whiteSpace: 'nowrap' }}>
                                                <p>{t('cart.registerNow')}</p>
                                            </div>
                                        </Link> */}
                                        <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                                            <div className='btn-white fs-14 translateY' style={{width:'80%', margin:'0 auto'}}>
                                                <p>{t('cart.loginNow')}</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                    }
                    </div>
                </Container>
            </div>

            {/* <Grid container justify="center" className={styles.breadcrumbRoot} style={{ margin: '0', maxWidth: '100%' }}>
                <Grid item xs={10} md={5}>
                    <Stepper activeStep={0} alternativeLabel className={styles.stepperStyle}>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { root: styles.icon } }}>
                                {t('checkout.myCart')}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                {t('checkout.fillInfo')}
                            </StepLabel>
                        </Step>
                        <Step>
                            <StepLabel StepIconProps={{ classes: { text: styles.whiteText } }}>
                                {t('checkout.submitOrder')}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </Grid>
            </Grid> */}

            <div>
                {/* {
                    accessToken
                        ? null
                        : <Container>
                        <Alert
                            severity="warning"
                            style={{ lineHeight: 1.2, fontSize: 11 }}
                            action={
                                <>
                                    <Link underline='none' color="inherit" to="/register" component={RouterLink} style={{ "marginRight": 10 }}>
                                        <Button variant="contained" size="small" style={{ borderRadius: 49, background: theme.palette.primary.main }}>
                                            <Typography variant="caption" className='txt-title' style={{ fontSize: 11, color: '#f0f0f0' }}>{t('cart.registerNow')}</Typography>
                                        </Button>
                                    </Link>
                                    <Link underline='none' color="inherit" to="/login" component={RouterLink}>
                                        <Button variant="contained" size="small" style={{ borderRadius: 49, background: theme.palette.primary.main }}>
                                            <Typography variant="caption" className='txt-title' style={{ fontSize: 11, color: '#f0f0f0' }}>{t('cart.loginNow')}</Typography>
                                        </Button>
                                    </Link>
                                </>
                            }
                        >
                            {t('cart.noLogin')}
                        </Alert>
                        </Container>
                } */}
                <Container>
                    <div style={{marginTop:-60}}>
                        {
                            _.size(cart) > 0 && cart !== null ?
                                _.map(cart, (merchantItem, merchantId) => {
                                    return (
                                        <div key={merchantId}>
                                            {/* <Box border={1} display="flex" flexDirection="row" alignItems="center" borderColor={theme.palette.gray.border} style={{ padding: 10, backgroundColor: '#0e386b', color: '#fff' }}>
                                                <StorefrontIcon fontSize="small" style={{ marginLeft: 20 }} />
                                                <Typography variant="caption" style={{ fontWeight: 'bold', fontSize: 14, marginLeft: 5 }}>{merchantItem.name}</Typography>
                                            </Box> */}
                                            {
                                                _.map(merchantItem.items, (cartDetails, shippingChannel) => {
                                                    return (
                                                        _.map(cartDetails, (cartItem) => {
                                                            const title_translate = i18n.language === 'cn' ? cartItem.product.title_cn : cartItem.product.title_en;
                                                            let variant_translate = [];
                                                            let stockAvailable = 0;
                                                            if (_.size(cartItem.product.options) > 0) {
                                                                _.map(cartItem.product.options, (option) => {
                                                                    _.map(option.pricing, (pricingOption) => {
                                                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                            if (option.color) {
                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.color_cn ? option.color_cn : option.color_en) : option.color_en)}`);
                                                                            }
                                                                            if (option.size) {
                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.size_cn ? option.size_cn : option.size_en) : option.size_en)}`);
                                                                            }
                                                                            if (option.attribute_value) {
                                                                                variant_translate.push(`${(i18n.language === 'cn' ? (option.attribute_value_cn ? option.attribute_value_cn : option.attribute_value_en) : option.attribute_value_en)}`);
                                                                            }
                                                                            stockAvailable = pricingOption.quantity;
                                                                        }
                                                                    })
                                                                });
                                                            } else {
                                                                stockAvailable = cartItem.product.quantity;
                                                            }
                                                            return (
                                                                <div key={cartItem.id}>
                                                                    {
                                                                        (shippingChannel === 'unlisted')
                                                                            ? 
                                                                            <div className='shadow-in m-b-20 p-lr-15 p-tb-10 bor15 translateY' style={{ background: '#ffffff42', boxShadow:'rgba(0, 0, 0, 0.133) 2px 2px 4px 0px inset, inset 0 -3px 4px 0 #ffffff', backdropFilter:'blur(12px)' }}>
                                                                                <div className='flex-sb-m p-tb-10 w-full'>
                                                                                        <Link underline='none' color="inherit" to={`/product/${cartItem.product.id}`} component={RouterLink} style={{ lineHeight: 0}}>
                                                                                        <div className={`${styles.imageSize} bor10`} style={{ background: cartItem?.product?.display_code?.default ? '#fff' : cartItem?.product?.display_code?.background_color }}>
                                                                                                <WebpImg className={`${styles.imageSize} bor10`} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                            </div>
                                                                                        </Link>
                                                                                    <div className='w-full p-l-15'>
                                                                                        <div className='flex-sb-m w-full'>
                                                                                            <div className='w-full p-r-15 cl-theme'>
                                                                                                <p className='txt-upper fs-remark'>{t('product.unlistedProduct')}</p>
                                                                                                <p className='fs-title txt_truncate1 lh-11'>{title_translate}</p>
                                                                                            </div>

                                                                                            <div className='btn-square25-theme' onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}>
                                                                                                <LuTrash/>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div>
                                                                                            {
                                                                                                _.size(variant_translate) > 0
                                                                                                    ? 
                                                                                                        <div className='p-t-8'>
                                                                                                            <div className='box-variant flex-m fs-remark cl-theme' style={{ background: 'transparent', boxShadow:'2px 2px 3px 0px #ffffff4a, 1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset' }}>
                                                                                                                <p className='cl-theme'>{t('cart.variations')}: </p>
                                                                                                                {
                                                                                                                    _.map(variant_translate, (variantText, key) => {
                                                                                                                        return (
                                                                                                                            <p key={variantText} className='cl-theme'>
                                                                                                                                {key > 0 ? ", " : " "}{variantText}
                                                                                                                            </p>
                                                                                                                        )
                                                                                                                    })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                            
                                                                                        {/* <Box>{priceDisplay(cartItem, true, 'mobile')}</Box> */}

                                                                                        <div className='flex-sb-m w-full p-t-10 fs-content cl-theme'>
                                                                                            <p>{t('product.quantity')}: {cartItem.quantity}</p>
                                                                                            <p className='txt-right'>
                                                                                                <b>
                                                                                                    {
                                                                                                        _.map(cartItem.product.options, (optionItem) => (
                                                                                                            _.map(optionItem.pricing, (pricingOption, key) => {
                                                                                                                if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                                                    // let priceMod = cartItem.product.type === 'rank' ? (starter ? pricingOption.price_mod_starter : pricingOption.price_mod_repurchase) : pricingOption.price_mod;
                                                                                                                    let priceMod = pricingOption.price_mod;
                                                                                                                    let productPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : cartItem?.product?.sell_price;

                                                                                                                    return <NumberFormat key={key} value={(cartItem?.quantity * (parseFloat(priceMod) > 0 ? parseFloat(priceMod) : productPrice) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                                                }
                                                                                                            })
                                                                                                        ))
                                                                                                    }
                                                                                                </b>
                                                                                            </p>
                                                                                        </div>

                                                                                        {
                                                                                            cartItem.quantity > stockAvailable &&
                                                                                            <div className='txt-right cl-theme'>
                                                                                                <p className='fs-remark cl-error'>{t('cart.stockAvailable')}: {stockAvailable}</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            : 
                                                                            <>
                                                                            
                                                                            <div className='shadow-glass2 glass2 m-b-20 p-lr-15 p-tb-10 bor15 translateY' >
                                                                                <div className='flex-sb-m w-full'>
                                                                                    <div className="pos-relative" >
                                                                                        <Link underline='none' color="inherit" to={`/product/${cartItem.product.id}`} component={RouterLink}  style={{lineHeight:0}}>
                                                                                            <div className={`${styles.imageSize} bor10`} style={{ background: cartItem?.product?.display_code?.default ? '#fff' : cartItem?.product?.display_code?.background_color }}>
                                                                                                <WebpImg className={`${styles.imageSize} bor10`} alt={cartItem.product.id} src={cartItem.product.images_array[0] ? cartItem.product.images_array[0] : '/images/logo.png'} />
                                                                                            </div>
                                                                                        </Link>
                                                                                        <div className='pos-absolute' style={{ top: 0, left: 0 }}>
                                                                                            <Checkbox
                                                                                                style={{ padding: 0 }}
                                                                                                checked={Boolean(cartItem.selected)}
                                                                                                icon={<Circle className='bgwhite cl-theme' style={{ borderRadius: 50 }} />}
                                                                                                checkedIcon={<FaCheck className='bg-theme clwhite p-all-3' style={{ borderRadius: 50, border: '1px solid #fff' }} />}
                                                                                                onChange={() => updateCart(cartItem.id, null, true)}
                                                                                            />
                                                                                        </div>
                                                                                    </div>

                                                                                    <Box style={{ paddingLeft: 15, width: '100%' }}>
                                                                                        <div className='flex-sb-m' style={{ alignItem: 'flex-start' }}>
                                                                                            <div className='p-r-10'>
                                                                                                <Link underline='none' color="inherit" to={`/product/${cartItem.product.id}`} component={RouterLink}><p className='txt_truncate1 cl-theme lh-11 fs-title'><b>{title_translate}</b></p></Link>
                                                                                            </div>

                                                                                            <div className="btn-square25-white" style={{ border: '1px solid #0d1d3c52', boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.07)', background: '#ffffff2b' }}  onClick={() => setState({ ...state, dialogShow: true, removeCartId: [cartItem.id] })}>
                                                                                                <IoMdTrash className='fs-16 cl-theme' />
                                                                                            </div>
                                                                                        </div>

                                                                                        <div>
                                                                                            {
                                                                                                _.size(variant_translate) > 0
                                                                                                    ? 
                                                                                                        <div className='p-t-8'>
                                                                                                            <div className='box-variant flex-m fs-remark cl-theme' style={{ background: 'transparent' }}>
                                                                                                                <p className='cl-theme'>{t('cart.variations')}: </p>
                                                                                                                {
                                                                                                                    _.map(variant_translate, (variantText, key) => {
                                                                                                                        return (
                                                                                                                            <p key={variantText} className='cl-theme'>
                                                                                                                                {key > 0 ? ", " : " "}{variantText}
                                                                                                                            </p>
                                                                                                                        )
                                                                                                                    })}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    : null
                                                                                            }
                                                                                        </div>
                                                                                        <div>{priceDisplay(cartItem, false, 'mobile')}</div>

                                                                                        <div className='flex-sb-m w-full'>
                                                                                            <FormControl style={{ margin: '5px 0' }}>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    value={cartItem.quantity}
                                                                                                    disableUnderline={true}
                                                                                                    fullWidth={true}
                                                                                                    classes={{ input: styles.inputStyle }}
                                                                                                    onChange={({ target }) => updateCart(cartItem.id, target.value)}
                                                                                                    startAdornment={
                                                                                                        <InputAdornment position="start">
                                                                                                            <div
                                                                                                                className='btn-square20-theme'
                                                                                                                onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) - 1)}
                                                                                                            >
                                                                                                                <FiMinus className='fs-icon-small' />
                                                                                                            </div>
                                                                                                        </InputAdornment>
                                                                                                    }
                                                                                                    endAdornment={
                                                                                                        <InputAdornment position="end">
                                                                                                            <div
                                                                                                                className='btn-square20-theme'
                                                                                                                onClick={() => updateCart(cartItem.id, parseInt(cartItem.quantity) + 1)}
                                                                                                            >
                                                                                                                <FiPlus className='fs-icon-small' />
                                                                                                            </div>
                                                                                                        </InputAdornment>
                                                                                                    }
                                                                                                />
                                                                                            </FormControl>
                                                                                            {/* <p className='fs-15 cl-theme txt-right'>
                                                                                                <b>USD 88,888.00</b>
                                                                                            </p> */}
                                                                                            <p className='fs-15 cl-theme txt-right'>
                                                                                                <b>
                                                                                                    {
                                                                                                        _.size(cartItem.product.options) > 0
                                                                                                            ? _.map(cartItem.product.options, (optionItem) => (
                                                                                                                _.map(optionItem.pricing, (pricingOption) => {
                                                                                                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                                                                                                        // let priceMod = cartItem.product.type === 'rank' ? (starter ? pricingOption.price_mod_starter : pricingOption.price_mod_repurchase) : pricingOption.price_mod;
                                                                                                                        let priceMod = pricingOption.price_mod;
                                                                                                                        let productPrice = parseFloat(priceMod) > 0 ? parseFloat(priceMod) : cartItem?.product?.sell_price;

                                                                                                                        return <NumberFormat key={pricingOption.id} value={(cartItem?.quantity * (parseFloat(priceMod) > 0 ? parseFloat(priceMod) : productPrice) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                                                    }
                                                                                                                })
                                                                                                            ))
                                                                                                            : <NumberFormat value={(cartItem.quantity * cartItem.product.sell_price * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                                                                    }
                                                                                                </b>
                                                                                            </p>
                                                                                        </div>
                                                                                        <Box>
                                                                                            {
                                                                                                cartItem.quantity > stockAvailable
                                                                                                    ? <Grid item xs={12} style={{ padding: 10, alignSelf: 'center', textAlign: 'right' }}>
                                                                                                        <Typography variant="caption" color="error">{t('cart.stockAvailable')}: {stockAvailable}</Typography>
                                                                                                    </Grid>
                                                                                                    : null
                                                                                            }
                                                                                        </Box>
                                                                                    </Box>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            </>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                })
                                            }
                                    </div>
                                )
                            })
                            :
                            <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                                {
                                    cart === null 
                                        ? <>
                                            <CircularProgress disableShrink className='p-b-10 m-t-100'/>
                                            <Typography variant="caption">{t('general.fetchingData')}</Typography>
                                        </>
                                        : <Box>
                                            <WebpImg className={styles.imgStyle} alt={'emptycart'} src={'/images/empty/cart.png'} containerStyle={{ width: 150, maxWidth: '50%', margin:'0 auto' }}  style={{width:'100%'}} />
                                            <Typography className='txt-center'>{t('cart.startShopping')}</Typography>
                                        </Box>
                                }
                            </Box>
                        }
                    </div>
                </Container>

                {/* delete dialog */}
                <Dialog
                    open={state.dialogShow}
                    onClose={() => setState({ ...state, dialogShow: false })}
                    aria-labelledby="draggable-dialog-title"
                >

                    <DialogContent>
                        <p className='fs-header p-b-10 cl-theme'>{t('cart.removeTitle')}</p>
                        <div className='flex-c p-b-20'>
                            <FiAlertTriangle className='cl-theme fs-45'/>
                            <p className='fs-content p-l-10'>{t('cart.removeContent')}</p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='btn-outlined-theme bor50' onClick={() => setState({...state, dialogShow: false, removeCartId: []})}>
                            <p>{t('button.cancel')}</p>
                        </div>
                        <div className='btn-theme bor50' onClick={removeCart}>
                            <p>{t('button.confirm')}</p>
                        </div>
                    </DialogActions>
                </Dialog>

                {/* checkout part */}
                {
                    _.size(cart) > 0 && cart !== null
                        ? <Box style={{ backgroundColor: '#f2f2f2', padding: '10px 15px 18px', position: 'fixed', bottom: -7, width: 420, zIndex: 10, maxWidth: '100%', minHeight: 80, display: 'flex', alignItems: 'center' }}>
                            <Grid item xs={12} sm={11} style={{ margin: '0 auto' }}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box style={{ textAlign: 'start' }} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={state.checkAll}
                                                    icon={<Circle className='bgwhite cl-theme' style={{ borderRadius: 50 }} />}
                                                    checkedIcon={<FaCheck className='bg-theme clwhite p-all-3' style={{ borderRadius: 50, border: '1px solid #fff' }} />}
                                                    onChange={checkAll}
                                                />
                                            }
                                            label={
                                                <Box>
                                                    <Typography variant="subtitle2" style={{ color: '#383838' }}>{t('cart.all')}</Typography>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                    <Box className='flex-r' classes={{ root: styles.tableCellStyle }} style={{ marginLeft: '5px' }}>
                                        <Box classes={{ root: styles.tableCellStyle }} className='p-r-10'>
                                            <Box>
                                                <Typography variant="overline" style={{ fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: '11px' }}>
                                                    {t('cart.subTotal')}: <br />
                                                    <NumberFormat style={{ whiteSpace: 'nowrap', fontSize: 14, color: '#0f386b' }} className={styles.priceStyle} value={currencyFormat(state.totalPrice * currencyRate[`${currency}`])} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                </Typography>
                                            </Box>
                                            {
                                                _.size(state.totalWalletPrice) > 0
                                                    ? _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                                        return (
                                                            <Box key={walletType}>
                                                                <Typography variant="overline" style={{ color: 'white', fontWeight: 'bold', lineHeight: 'unset', whiteSpace: 'nowrap', fontSize: 11 }} className={styles.priceStyle}>
                                                                    <NumberFormat className={styles.priceStyle} style={{ whiteSpace: 'nowrap', fontSize: 14, color: '#0f386b' }} value={currencyFormat(walletTotal)} displayType={'text'} decimalScale={2} thousandSeparator={true} prefix={`${walletType} `} />
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    })
                                                    : null
                                            }
                                        </Box>

                                        <Button variant="contained" size="large" style={{ borderRadius: 49, backgroundColor: (state.totalSelected > 0 ? '#0e386b' : '#e0e2e2'), color: (state.totalSelected > 0 ? '#fff' : '#8e8d8d'), padding: '5px 12px' }} disabled={state.totalSelected > 0 ? false : true} onClick={checkoutCart}>
                                            <Typography className='txt-title'>{t('cart.checkout')}</Typography>
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>
                        : null
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    breadcrumbRoot: {
        padding: '10px 0 10px 0',
        backgroundColor: 'light',
    },
    root: {
        padding: '10px 0 10px 0',
    },
    inputStyle: {
        textAlign: 'center',
        width: 30,
        fontSize: 16,
        padding: 5,
    },
    priceStyle: {
        fontSize: 14,
        // color: theme.palette.secondary.main,
        color: 'white',
    },
    icon: {
        color: `${theme.palette.button.step} !important `,
    },
    whiteText: {
        fill: '#fff'
    },
    stepperStyle: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    containerStyle: {
        borderWidth: 20
    },
    tableCellStyle: {
        borderBottom: 0,
        textAlign: 'end'
    },

    imageSize: {
        borderRadius: 15,
        aspectRatio: 1 / 1,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            height: 80,
        },
        [theme.breakpoints.up('sm')]: {
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            height: 80,
        },
    },
    root2: {
        // padding: '10px 0px 30px 0px',
        background: theme.palette.white.mobileBkg,
        // padding:'0 0 80px',
        minHeight: '100%'
    },
    textline2: {
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    },
    textline1: {
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden'
    }
}));