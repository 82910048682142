import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { storeWalletBalance } from '@actions';
import { getUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, InputAdornment, IconButton, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Slider from "react-slick";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

//ICON
import { Search } from '@material-ui/icons';
import { FiChevronRight } from "react-icons/fi";

//COMPONENTS & LAYOUTS
import CollectionItemCard from '@components/CollectionItemCard';
import ProductItemCard from '@components/ProductItemCard';
import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';

import 'react-awesome-slider/dist/styles.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// function NextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <Hidden only='xs'>
//             <div
//                 className={className}
//                 style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
//                 onClick={onClick}
//             />
//         </Hidden>
//     );
// }
// function PrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <Hidden only='xs'>
//             <div
//                 className={className}
//                 style={{ ...style, display: "block", backgroundColor: 'transparent', borderRadius: 30, paddingTop: 1 }}
//                 onClick={onClick}
//             />
//         </Hidden>
//     );
// }

export default function Home() {
    const theme = useTheme();
    const styles = useStyles();
    const isMountedRef = useRef(null);
    const history = useHistory();
    const descriptionElementRef = useRef(null);
    
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { accessToken } = useSelector(state => ({
        accessToken: state.general.accessToken,
        walletBalance: state.user.walletBalance,
    }));
    const { currency, currencyDisplay } = useSelector(state => state.general);

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const [recommend, setRecommend] = useState(null);
    const [vouchers, setVouchers] = useState(null);
    const [collections, setCollections] = useState(null);
    const [slideBanner, setSlideBanner] = useState(null);
    const [merchantList, setMerchantList] = useState([]);
    const [announcements, setAnnouncements] = useState([]);
    const [viewAnnouncement, setViewAnnouncement] = useState({});
    const [unreadCount, setUnreadAnnouncementCount] = useState(0);
    const [searchKeyword, setSearchKeyword] = useState({ keyword: '' });

    // ------------- Variables ---------------
    let currency_code = [];
    _.map(currencyDisplay, (currency_detail) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // ------------- APIs ---------------
    useEffect(() => {
        isMountedRef.current = true;

        getUrl('products', { exclude: 'collections' })
            .then(productList => {
                if (isMountedRef.current) {
                    setRecommend(productList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        getUrl('collections')
            .then(collectionList => {
                if (isMountedRef.current) {
                    setCollections(collectionList.data);
                    setVouchers(collectionList.voucher)
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        getUrl('sliderbanners')
            .then(slideBannerList => {
                if (isMountedRef.current) {
                    setSlideBanner(slideBannerList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        getUrl('companies')
            .then(merchantList => {
                if (isMountedRef.current) {
                    setMerchantList(merchantList.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });

        if (accessToken) {
            getUrl('wallets/balance').then(response => {
                if (response.status && isMountedRef.current) {
                    dispatch(storeWalletBalance(response.data));
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            })

            getUrl('member_announcements').then(res => {
                if (isMountedRef.current && res.status) {
                    setAnnouncements(res.data);
                    setUnreadAnnouncementCount(res.new_count);
                    let show = { 0: false };
                    let today = new Date();

                    _.map(res.data, (row, index) => {
                        if (row.popup && _.size(row['users']) === 0 && today >= new Date(row.popup_sdate) && today <= new Date(row.popup_edate)) show[index] = true;
                    });
                    setViewAnnouncement({ ...viewAnnouncement, ...show });

                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    useEffect(() => {
        if (viewAnnouncement !== "") {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [viewAnnouncement]);

    // ------------- Functions ---------------
    const closeAnnouncement = (index) => {
        setViewAnnouncement({ ...viewAnnouncement, [index]: false });
        getUrl(`member_announcements/${announcements[index]['id']}`).then(result => {
            if (result.status === 1) {
                setUnreadAnnouncementCount(unreadCount - 1);
                window.location.reload();
            }
        }).catch((error) => {
        });
    }

    const handleSearch = (event) => {
        history.push(`/category/all?s=${searchKeyword?.keyword}`);
    };

    const handleSearchChange = (event) => {
        setSearchKeyword({ ...searchKeyword, keyword: event.target.value });
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            history.push(`/category/all?s=${searchKeyword?.keyword}`);
        }
    };

    // ------------- Components ---------------
    const bannerSlider = () => {
        return (
            <div className='bor15 m-b-20' style={{ boxShadow: '0px 6px 11px 0 #00000026' }}>
                {
                    _.size(slideBanner) > 0 && slideBanner !== null
                        ?
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <AutoplaySlider
                                play={true}
                                cancelOnInteraction={false}
                                interval={3000}
                                autoplay={true}
                                bullets={false}
                                className='home'
                                organicArrows={false}
                            >
                                {
                                    _.map(slideBanner, (slideBannerLists) => {
                                        return (
                                            <div className="awssld__content" key={slideBannerLists.id} >
                                                <WebpImg src={slideBannerLists.file_name} alt="slide" style={{ objectFit: 'cover', display: 'block', width: '100%' }} />
                                            </div>
                                        )
                                    })
                                }
                            </AutoplaySlider>
                        </Grid>
                        :
                        null
                }
            </div>
        )
    }

    const merchantSlider = () => {
        const sliderSettings = {
            dots: false,
            infinite: merchantList.length > 1,
            arrows: false,
            speed: 500,
            slidesToShow: 2.5,
            slidesToScroll: 2.5,
            initialSlide: 0,
            autoplay: true,
            autoplaySpeed: 3000,
            swipe: true,
            // touchMove: true,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2.5,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2.5,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2.5,
                        slidesToScroll: 2.5,
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }
            ],
        };
    
        return (
            <div>
                <div className='w-full flex-sb-m'>
                    <p className='fs-title cl-theme txt-upper'>{t('home.shop')}</p>
                    <Link underline="none" to={`/category/shop/all`} component={RouterLink} className="translateX flex-m">
                        <p className="fs-content cl-theme p-r-5">{t('shop.seeAll')}</p>
                        <FiChevronRight className="fs-icon-small cl-theme" />
                    </Link>
                </div>
                <div className='w-full p-t-10 p-b-20'>
                    {merchantList.length > 0 ? (
                        <Slider {...sliderSettings}>
                            {merchantList.map((merchant) => (
                                // <div
                                //     className="merchant-slide"
                                //     key={merchant.id}
                                //     style={{ boxSizing: 'border-box', height: '100%' }}
                                // >
                                //     <div
                                //         className="merchant-content"
                                //         style={{
                                //             textAlign: 'center',
                                //             boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                //             borderRadius: '10px',
                                //             padding: '10px',
                                //             backgroundColor: '#fff',
                                //         }}
                                //     >
                                //         <WebpImg
                                //             src={merchant.merchant_company_icon?.file_name || 'default-placeholder.jpg'}
                                //             alt={merchant?.[`shop_name_${i18n.language}`] || 'Merchant'}
                                //             style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '8px', marginBottom: '10px', aspectRatio: '1/1' }}
                                //         />
                                //         <Typography variant='body2' style={{ fontWeight: 'bold' }}>{merchant?.[`shop_name_${i18n.language}`]}</Typography>
                                //     </div>
                                // </div>
                                <Link key={merchant?.id} underline='none' to={`/shop/${merchant?.slug}`} component={RouterLink}>
                                    <div className='bor15 pointer translateY m-r-20 m-t-10' style={{ background: '#fff', boxShadow: theme.shadows[2], width: 85, height: 85, lineHeight: 0, aspectRatio: '1/1', objectFit: 'cover' }}>
                                        <WebpImg
                                            src={_.size(merchant?.merchant_company_icon) > 0 ? merchant?.merchant_company_icon?.file_name : '/images/empty/noimage.jpg'}
                                            alt="merchant"
                                            className='bor15'
                                            style={{ width: '100%', height: '100%', objectFit: 'cover', aspectRatio: '1/1', padding: 5  }}
                                        />
                                        <Typography className='txt-center p-t-5' style={{ fontSize: 12, fontWeight: 'bold', lineHeight: 1.1 }}>
                                            {merchant?.[`shop_name_${i18n.language}`]}
                                        </Typography>
                                    </div>
                                </Link>
                            ))}
                        </Slider>
                    ) : (
                        <p>{t('general.loadingMerchant')}</p>
                    )}
                </div>
            </div>
        );
    };    

    const starterProduct = () => {
        let starter = _.find(collections, function (c) { return c.id === 1; });
        if (starter && (_.size(starter.product_ids_array) > 0)) {
            return (
                <div>
                    <p className='fs-title txt-upper cl-theme p-b-5'>{t('title.membership')}</p>
                    <Link underline='none' component={RouterLink} to="/collection/1">
                        <div className='w-full pointer translateY'>
                            <img src={`/images/banner/membership-${i18n.language}.png`} alt="membership" className='w-full bor15 shadow-glass2' />
                        </div>
                    </Link>
                </div>
            )
        }
        return null;
    }

    const collectionList = () => {
        if (_.size(collections) > 0) {
            return (
                <div>
                    {
                        _.map(collections, collectionItem => {
                            if (_.size(collectionItem.product_ids_array) > 0) {
                                if (collectionItem.id !== 1) {
                                    return (
                                        <div className='p-tb-20' key={collectionItem.id}>
                                            <div className='w-full flex-sb-m'>
                                                <p className='fs-title cl-theme txt-upper'>{collectionItem[`title_${i18n.language}`]}</p>
                                                <Link underline='none' to={`/collection/${collectionItem.id}`} component={RouterLink} className='translateX flex-m'>
                                                    <p className='fs-content cl-theme p-r-5'>{t('shop.seeAll')}</p>
                                                    <FiChevronRight className='fs-icon-small cl-theme' />
                                                </Link>
                                            </div>
                                            <Slider
                                                infinite={true}
                                                speed={500}
                                                slidesToShow={_.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1}
                                                slidesToScroll={1}
                                                autoplay={true}
                                                pauseOnHover={true}
                                                bullets={true}
                                                arrows={false}
                                                adaptiveHeight={true}
                                                responsive={[
                                                    { breakpoint: 1024, settings: { slidesToShow: _.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1, slidesToScroll: 1, infinite: true, dots: true } },
                                                    { breakpoint: 992, settings: { slidesToShow: _.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1, slidesToScroll: 1, initialSlide: 2, dots: true } },
                                                    { breakpoint: 600, settings: { slidesToShow: _.size(collectionItem.product_ids_array) > 1 && collectionItem?.id !== 1 ? 2 : 1, slidesToScroll: 1, initialSlide: 2, dots: true } },
                                                ]}
                                                customPaging={(i) => (
                                                    <div className="custom-dot"></div> // You can use <CustomDot /> if you created the CustomDot component
                                                )}
                                                appendDots={(dots) => (
                                                    <div>{dots}</div>
                                                )}
                                            // nextArrow={<NextArrow />}
                                            // prevArrow={<PrevArrow />}
                                            >
                                                {
                                                    _.map(_.slice(collectionItem.product_ids_array, 0, 6), (product) => {
                                                        return (
                                                            <Grid item xs={12} key={product.id} className={styles.cardContentRootStyle}>
                                                                {
                                                                    collectionItem?.id === 1
                                                                        ? <CollectionItemCard product={product} currencyCode={currency_code} />
                                                                        : <ProductItemCard product={product} currencyCode={currency_code} />
                                                                }
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Slider>
                                        </div>
                                    )
                                }
                            }
                        })
                    }
                </div>
            )
        }
        return null;
    }

    const productList = () => {
        return (
            <>
                <div className='w-full flex-sb-m'>
                    <p className='fs-title cl-theme txt-upper'>{t('home.dailyRecommend')}</p>
                    <Link underline='none' to={`/category/all`} component={RouterLink} className='translateX flex-m'>
                        <p className='fs-content cl-theme p-r-5'>{t('shop.seeAll')}</p>
                        <FiChevronRight className='fs-icon-small cl-theme' />
                    </Link>
                </div>
                {
                    _.size(recommend) > 0
                        ?
                        <Grid container spacing={1} style={{ alignItems: 'stretch' }}>
                            {
                                _.map(recommend, (product) => {
                                    return (
                                        <Grid item xs={6} sm={6} key={product.id} className={styles.cardContentRootStyle}>
                                            <ProductItemCard product={product} currencyCode={currency_code} />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        :
                        <p className='fs-title txt-center cl-theme'>
                            {t('general.noProduct')}
                        </p>
                }
            </>
        )
    }

    const voucherList = () => {
        return (
            <div style={{ paddingBottom: 30 }}>
                <div className='w-full flex-sb-m'>
                    <p className='fs-title cl-theme txt-upper'>{t('home.voucher')}</p>
                    <Link underline='none' to={`/vouchers`} component={RouterLink} className='translateX flex-m'>
                        <p className='fs-content cl-theme p-r-5'>{t('shop.seeAll')}</p>
                        <FiChevronRight className='fs-icon-small cl-theme' />
                    </Link>
                </div>
                <Slider
                    infinite={true}
                    speed={500}
                    slidesToShow={_.size(vouchers) > 1 ? 2 : 1}
                    slidesToScroll={1}
                    autoplay={true}
                    pauseOnHover={true}
                    bullets={false}
                    arrows={false}
                    adaptiveHeight={true}
                    responsive={[
                        { breakpoint: 1024, settings: { slidesToShow: _.size(vouchers) > 1 ? 2 : 1, slidesToScroll: 1, infinite: true, dots: false } },
                        { breakpoint: 992, settings: { slidesToShow: _.size(vouchers) > 1 ? 2 : 1, slidesToScroll: 1, initialSlide: 2, dots: false } },
                        { breakpoint: 600, settings: { slidesToShow: _.size(vouchers) > 1 ? 2 : 1, slidesToScroll: 1, initialSlide: 2, dots: false } },
                    ]}
                    customPaging={(i) => (
                        <div className="custom-dot"></div> // You can use <CustomDot /> if you created the CustomDot component
                    )}
                    appendDots={(dots) => (
                        <div>{dots}</div>
                    )}
                    
                // nextArrow={<NextArrow />}
                // prevArrow={<PrevArrow />}
                >
                    {
                        _.map(vouchers, (product) => {
                            return (
                                <Grid item xs={12} sm={12} key={product.id} className={styles.cardContentRootStyle}>
                                    <ProductItemCard product={product} currencyCode={currency_code} />
                                </Grid>
                            )
                        })
                    }
                </Slider>
            </div>
        )
    }

    const announcementList = () => {
        return (
            <div>
                {
                    _.size(announcements) ?
                        _.map(announcements, (row, index) => (
                            row.popup && new Date() >= new Date(row.popup_sdate) && new Date() <= new Date(row.popup_edate) ?
                                <Dialog
                                    open={viewAnnouncement[index] ? true : false}
                                    onClose={() => closeAnnouncement(index)}
                                    scroll="body"
                                    aria-labelledby="view Announcement"
                                    aria-describedby="view Announcement detail"
                                    key={index}
                                >
                                    <DialogTitle id={`scroll-dialog-title${index}`}>{row[`title_${i18n.language}`]}</DialogTitle>
                                    <DialogContent dividers={false}>
                                        <div
                                            id="viewAnnouncement"
                                            ref={descriptionElementRef}
                                            tabIndex={-1}

                                        >
                                            {row['image'] ?
                                                <WebpImg containerStyle={{ maxWidth: "80vw", display: 'flex', justifyContent: 'center', alignItems: 'center' }} src={row['image']['file_name']} style={{ width: '100%', padding: '0', display: 'block', borderRadius: 15, objectFit: 'cover', maxWidth: "80vw" }} alt="announcement" />
                                                : null
                                            }
                                            <div dangerouslySetInnerHTML={{ __html: row['content'][`${i18n.language}`] }} />
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => closeAnnouncement(index)} color="primary">{t("button.close")}</Button>
                                    </DialogActions>
                                </Dialog>
                                : null
                        ))
                        : null
                }
            </div>
        )
    }

    return (
        <div style={{ backgroundImage:'url(/images/general/bkg/gradient.png)', backgroundSize:'cover', backgroundRepeat:'repeat-y', backgroundPosition:'center center'}}>
            <TitleBar currencyButton displayCart logoIcon spendButton />
            <div>
                <Container fixed className={styles.root} style={{ padding: 20 }}>
                    <div className='p-b-15'>
                        <TextField
                            variant='filled'
                            value={searchKeyword?.keyword}
                            fullWidth
                            placeholder={t('home.search')}
                            className={styles.textFieldRoot}
                            onChange={handleSearchChange}
                            onKeyPress={handleKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <IconButton style={{ padding: 0, width: 30, height: 30, aspectRatio: 1 / 1 }} onClick={handleSearch}>
                                        <InputAdornment position="end" className='w-full'>
                                            <Search />
                                        </InputAdornment>
                                    </IconButton>
                                ),
                            }}
                        />
                    </div>
                    {bannerSlider()}
                    {/* {merchantSlider()} */}
                    {/* {starterProduct()} */}
                    {_.size(vouchers) > 0 ?  voucherList() : null}
                    {collectionList()}
                    {productList()}
                    {announcementList()}
                </Container>
		<div style={{textAlign:'center'}}>
                    <p className='fs-content cl-theme p-r-5' style={{fontSize: '12px'}}>MOSE NET SYSTEM SOLUTION SDN. BHD. (1590625-V)</p>
                    <p className='fs-content cl-theme p-r-5' style={{fontSize: '12px'}}>Kuala Lumpur, Malaysia.</p>
		</div>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0px 30px 0px',
        maxWidth: '100%'
    },
    cardContentRootStyle: {
        display: 'flex',
        alignItems: 'flex-end',
        padding: '10px !important',
    },
    textFieldRoot: {
        '& .MuiFilledInput-root': {
            padding: '6px 12px',
            background: '#F6F7F9',
        },
        '& .MuiFilledInput-input': {
            padding: '6px 12px',
        },
    },
}));
