import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { useDispatch } from 'react-redux';
import { postUrl } from '@helper/ApiAction';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Container, TextField, Link } from '@material-ui/core';
import 'react-awesome-slider/dist/styles.css';
// import { Mail } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CONTACT_US_EMAIL } from '@configs/Config';
import useNotificationLoading from '@helper/useNotificationLoading';
import SendIcon from '@material-ui/icons/Send';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TitleBar from '@layouts/TitleBar';
import WebpImg from '@layouts/WebpImg';
import '@css/styles.css';

function useInput({ type, label, multiline }) {
    const [value, setValue] = useState("");
    const [error, setErrorValue] = useState("");
    const input = 
    <TextField label={label} variant="filled" error={error ? true : false}  helperText={error} value={value} onChange={e => setValue(e.target.value)} type={type} style={{ width: '100%', maxWidth: '100%', marginTop: '15px' }} multiline={multiline} />;
    return [value, input, setErrorValue];
}

export default function Home() {
    const { t } = useTranslation();
    const styles = useStyles();
    const classes = useStyles();
    // const dispatch = useDispatch();
    const { addAlert } = useNotificationLoading();
    // const [setMessageValue] = React.useState('Controlled');

    const [name, nameInput, nameValidate] = useInput({ type: "text", label: t('form.name'), multiline: false });
    const [email, emailInput, emailValidate] = useInput({ type: "text", label: t('form.email'), multiline: false });
    const [subject, subjectInput, subjectValidate] = useInput({ type: "text", label: t('form.subject'), multiline: false });
    const [message, messageInput, messageValidate] = useInput({ type: "text", label: t('form.message'), multiline: true });

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const validate = { name: nameValidate, email: emailValidate, subject: subjectValidate, message: messageValidate };

    // let history = useHistory();
    // const { accessToken } = useSelector(state => state.general);

    // const handleChange = (event) => {
    //     setMessageValue(event.target.value);
    // };

    const handleSubmit = (evt) => {
        evt.preventDefault();
        postUrl(`contactus`, {
            name,
            email,
            subject,
            message
        })
        .then((response) => {
            console.log(response);
            let {status, message, error} = response;
            if(status === 0){
                setErrorMessage(message);
                _.map(error, (value, key) => {
                    validate[key](value[0]);
                })
            }else{
                setSuccessMessage(message);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }

    // const chatWithAdmin = () => {
    //     postUrl('adminchats').then(response => {
    //         // console.log("adminchats",response);
    //         if (response.status) {
    //             history.push(`/customer-service`);
    //         }
    //     }).catch(error => {
    //         addAlert(JSON.stringify(error.message));
    //     });
    // }

    return (
        <div>
             <TitleBar displayCart btnRegister back backgroundColor="bg-theme" spendButton />
             <div className='bg-theme p-b-30 header-radius'>
                <div className='subtitle-box'>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('form.getInTouch')}</p>
                    <p className='cl-label fs-subheader lh-11 txt-center'>{t('contact.submessage')}</p>
                </div>
            </div>
            <Container className='p-b-100 p-t-30'>
                <div>
                    <div className='flex-sb-m w-full p-b-24'>
                        <div>
                            <p className='fs-title'>{t('contact.email')}</p>
                            <p className='fs-content'>{CONTACT_US_EMAIL}</p>
                        </div>
                        <Link underline='none' component={RouterLink} className={styles.footerFont} to={`mailto:${CONTACT_US_EMAIL}`}>
                            <div >
                                <WebpImg src="/images/general/icon/email.png" className={`${styles.moveTopHoverYellow} bor5`} style={{ height: 40}} alt=" icon" />
                            </div>
                        </Link>
                    </div>
                {/* <div className='p-b-24'>
                    <p className='fs-title'><b>{t('contact.address')}</b></p>
                    <p className='fs-content'>{t('address.projectAddress')}</p>
                </div> */}
                {/* <Box style={{paddingBottom:24}}>
                    <Typography style={{fontSize:17, color:'#757575'}}><b>{t('contact.address')}</b></Typography>
                    <Typography style={{fontSize:14}}>014-966 1502</Typography>
                </Box> */}
                {/* <Box style={{paddingBottom:24}}>
                    <Typography style={{fontSize:18, color:'#606060'}}><b>{t('contact.followUs')}</b></Typography>
                    <div style={{display:'flex', alignItems:'center', paddingTop:10}}>
                    <WebpImg src="/images/general/icon/insta.png" style={{ height: 55, borderRadius:18, boxShadow:'#00000036 4px 4px 17px 1px',marginRight:30 }} alt="insta" />
                    <WebpImg src="/images/general/icon/fb.png" style={{ height: 55, borderRadius:18, boxShadow:'#00000036 4px 4px 17px 1px' }} alt="fb" />
                    </div>
                </Box> */}
                </div>

                <form onSubmit={handleSubmit}  noValidate autoComplete="off">
                    <div>
                    {successMessage ?
                            <div>
                                <p className='fs-title'>{t('support.dropUsAlLine')}</p>
                                <CheckCircleOutlineIcon style={{ color: 'green' }} className='fs-icon' />
                                {successMessage}
                            </div>
                            :
                            <div>
                                <p className='fs-title'><b>{t('support.dropUsALine')}</b></p>
                                <p className='fs-remark' style={{ color: 'red' }}>{errorMessage}</p>
                                {nameInput}
                                {emailInput}
                                {subjectInput}
                                {messageInput}

                                <div className='flex-r-m p-t-10'>
                                    <Button type="submit" className='translateX' variant="contained" color='primary'>
                                        <span>{t('button.sendNow')}</span>
                                        <SendIcon className='fs-icon m-l-15'/>
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </form>
            </Container>

            

            
        </div>
    );
}

const useStyles = makeStyles(theme => ({

    cardContentRootStyle: {
        padding: '5px !important',
    },
    cardRootStyle: {
        margin: '0px 1px 0px 1px'
    },
    buttonFont: {
        fontSize: '10px',
    },
    buttonStyle: {
        margin: '5px',
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    listItemStyle: {
        justifyContent: 'center',
        padding: '5px 0px 5px 0px',
    },
    cardStyle: {
        // minHeight: 380,
        borderRadius: 0
    },
    overlay: {
        position: 'absolute',
        top: '20px',
        left: '20px',
        color: 'black',
        backgroundColor: 'white'
    },
    moveTopHoverBlue:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(14 76 205 / 31%) 4px 4px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(39 115 242 / 41%) 0px 3px 4px 1px'
        }
    },
    moveTopHoverRed:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(232 68 65 / 31%) 1px 8px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(232 68 65 / 31%) 1px 3px 5px 1px'
            
        }
    },
    moveTopHoverYellow:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(228 166 25 / 40%) 4px 4px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(228 166 25 / 40%) 2px 3px 3px 1px'
        }
    },
    buttonSendHover:{
        marginTop: 0,
        transition: '0.8s',
        boxShadow:'rgb(228 166 25 / 40%) 4px 4px 17px 1px',
        "&:hover":{
            marginTop: -8,
            transition: '0.5s',
            boxShadow:'rgb(228 166 25 / 40%) 2px 3px 3px 1px'
        }
    }

    

    
}));
