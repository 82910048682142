import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@material-ui/core';

import { getUrl } from '@helper/ApiAction';
import TitleBar from '@layouts/TitleBar';
import useNotificationLoading from '@helper/useNotificationLoading';

import '@css/styles.css';

export default function Recruit() {
    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const [data, setData] = useState({
        left: {
            users_count: 0,
            bv: 0,
        },
        right: {
            users_count: 0,
            bv: 0,
        },
        new_member: 0,
        new_starter: 0,
        ttl_member: 0,
        team_list: [],
    });

    useEffect(() => {
        getUrl(`genealogy/summary`).then(response => {
            if (response.status) {
                setData(response.data);
            }
        }).catch(error => {
            addAlert(JSON.stringify(error.message));
        });
    }, []);

    return (
        <div>
            <TitleBar back backgroundColor="bg-theme" backUrl="/affiliates-centre" spendButton  />
            <div className='bg-theme p-b-80 header-radius clwhite txt-center'>
                <div className='subtitle-box '>
                    <p className='txt-center fs-header clwhite p-t-30'>{t('profile.communitySummary')}</p>
                    <p className='fs-subheader cl-label'>{t('profile.genealogySubtitle')}</p>
                </div>
            </div>
            <Container>
                <div style={{ marginTop: -40 }}>
                    <div className='flex-sa-m w-full gradient-blue p-tb-10 bor15 shadow-menu'>
                        <div className='clwhite txt-center' style={{ width: "45%" }}>
                            <p className='fs-menu-digit'>{t('genealogy.left')}</p>
                            <p className='fs-remark'>{data?.left?.users_count + " " + t('genealogy.users') + ' | ' + parseFloat(data?.left?.bv).toFixed(2) + " " + t('package.bv')}</p>
                        </div>
                        <div className='flex-c-m' style={{ width: "10%" }}>
                            <div style={{ width: 2, height: 30, backgroundColor: '#0003' }} />
                            <div style={{ width: 2, height: 30, backgroundColor: '#fff3' }} />
                        </div>
                        <div className='txt-center clwhite' style={{ width: "45%" }}>
                            <p className='fs-menu-digit'>{t('genealogy.right')}</p>
                            <p className='fs-remark'>{data?.right?.users_count + " " + t('genealogy.users') + ' | ' + parseFloat(data?.right?.bv).toFixed(2) + " " + t('package.bv')}</p>
                        </div>
                    </div>
                </div>
                <div className="p-tb-10" style={{ textAlign: "center" }}>
                    <p>{t('profile.todayNewMember') + ": "}<b>{data?.new_member}</b></p>
                    <p>{t('profile.todayNewSubscription') + ": "}<b>{data?.new_starter}</b></p>
                    <p>{t('profile.totalCommunityMember') + ": "}<b>{data?.ttl_member}</b></p>
                </div>
                <div>
                    <p className='cl-theme p-l-15 fs-content p-t-20'><b>{t('genealogy.teamList') + ' (' + _.size(data?.team_list) + ')'}</b></p>
                    {
                        _.map(data?.team_list, (username, key) => {
                            return (
                                <p key={key}>{(key + 1) + '. ' + username}</p>
                            )
                        })
                    }
                </div>
            </Container>
        </div>
    )
}