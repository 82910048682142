import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory , useLocation } from 'react-router-dom';

import { updateCartTotal, updateShareLinkRef } from '@actions';
import { getUrl, postUrl } from '@helper/ApiAction';
import useNotificationLoading from '@helper/useNotificationLoading';

// MUI
import { Box, Button, CircularProgress, Container, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// ICON
import { Edit3 } from 'react-feather';

// COMPONENTS & LAYOUTS
import TitleBar from '@layouts/TitleBar';

import CheckoutAddressCard from './CheckoutAddressCard';
import CheckoutBuyerCard from './CheckoutBuyerCard';
import CheckoutSellerCard from './CheckoutSellerCard';
import DirectCheckoutSellerCard from './DirectCheckoutSellerCard';

export default function Checkout() {
    const { t, i18n } = useTranslation();
    const styles = useStyles();
    let history = useHistory();
    const dispatch = useDispatch();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const isBuyNow = searchParams.has(btoa('buynow'));

    const { name, email, mobile, username, mobile_country_code, source } = useSelector(state => state.user);
    const [buyerInfo, setBuyerInfo] = useState({
        name: name,
        email: email ? email : '',
        mobile_country_code: mobile_country_code ? mobile_country_code : '',
        mobile: mobile ? mobile : '',
    });
    const [state, setState] = useState({
        open: false,
        error: false,
        message: '',
        modalShow: false,
        paymentId: null,
        addressId: null,
        totalPrice: 0,
        totalSelected: null,
        totalWalletPrice: {},
        totalShippingPrice: 0,
        walletBalance: {},
        selectAddressShow: false,
        orderSuccess: false,
        mode: null,
        editAddressId: null,
    });
    const [errorField, setErrorField] = useState({
        cart: []
    });
    const [cart, setCart] = useState([]);
    const [cartErrors, setCartError] = useState([]);
    const [updateCart, setUpdateCart] = useState(false);
    const [currencyData, setCurrencyData] = useState(null);
    const [addresses, setAddress] = useState(null);
    const [remark, setRemark] = useState({});
    const [mallShippingRefresh, setMallShippingRefresh] = useState(false);
    const [mallShipping, setMallShipping] = useState([]);
    const [mallTotalShipping, setMallTotalShipping] = useState(0);
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    const [fetchingMallShipping, setFetchingMallShipping] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [multiWalletBalance, setMultiWalletBalance] = useState([]);
    const [downlineInfo, setDownlineInfo] = useState({
        username: username ? username : '',
        source: source ? source : 'local',
    });
    const [shippingWallet, setShippingWallet] = useState({
        id: 0,
        prefix: '',
        decimal: 2,
    });
    const [totalPayment, setTotalPayment] = useState({
        cash: 0,
        wallet_list: {}
    });
    const [isMalaysiaShipping, setIsMalaysiaShipping] = useState(false);
    const [malaysiaShippingArea, setMalaysiaShippingArea] = useState("");
    const [selfPickUpInfo, setSelfPickupInfo] = useState({});
    const [mallFee, setMallFee] = useState({});
    const [pickup, setPickUp] = useState(0);
    const [walletPoint, setWalletPoint] = useState([]);
    const [buyNow, setBuyNow] = useState(isBuyNow ?? false);
    const [directPayment, setDirectPayment] = useState(false);
    const [directPaymentPrice, setDirectPaymentPrice] = useState([]);

    // CURRENCY
    const { currency, currencyRate, currencyDisplay, linkReferralCode } = useSelector(state => state.general);
    let currency_code = [];
    _.map(currencyDisplay, (currency_detail, index) => {
        if (currency_detail.code == currency) {
            currency_code = currency_detail.desc.split('|');
        }
    });

    // GET CART
    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            let apiData = {
                'buyNow': isBuyNow,
            };
            getUrl('carts' , apiData)
                .then(cartList => {
                    if (isMountedRef.current) {
                        setCurrencyData(cartList.currency_data);
                        setCart(cartList.data);
                        calculateTotal(cartList.data);
                        setupWalletPoint({ userCart: cartList.data, buyNow: cartList.buy_now});
                        setBuyNow(cartList.buy_now);
                        _.map(cartList.data, (merchantItem, merchantIndex) => {
                            let pickupType = merchantItem.self_pickup == 1;
                            if (_.size(selfPickUpInfo) <= 0) {
                                setSelfPickupInfo(prevState => ({
                                    ...prevState,
                                    [merchantIndex]: {
                                        id: merchantIndex,
                                        channel: pickupType ? 'pickup' : 'delivery',
                                        self_pickup: pickupType ? true : false,
                                        outlet_id: _.size(merchantItem.outlets) == 1 ? merchantItem.outlets[0].id : 0,
                                    }
                                }))
                            }
                            // if (cartList.buy_now){
                            //     setDirectPayment();
                            // }
                        })
                    }
                }).catch(error => {
                    addAlert(JSON.stringify(error.message));
                });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, updateCart, wallets, mallFee]);

    // GET WALLET BALANCE
    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef.current) {
            getUrl('wallets/balance').then(response => {
                if (isMountedRef.current && response.status === 1) {
                    setWallets(response.data);
                }
            }).catch(error => {
                addAlert(JSON.stringify(error.message));
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert]);

    // CALCULATE TOTAL
    useEffect(() => {
        calculateTotal(cart);
    }, [malaysiaShippingArea, cart, selfPickUpInfo]);

    // SET TOTAL PAYMENT
    useEffect(() => {
        let tempWallet = {};
        let cash = parseFloat(state.totalPrice);
        let walletCash = 0;

        _.map(walletPoint, (cartWallets, cartId) => {
            _.map(cartWallets, (amount, wid) => {
                if (wid !== 'cash' && amount > 0) {
                    let w = _.find(wallets, { 'id': parseInt(wid) })
                    let display_name = w.wallet_name ? w.wallet_name : w.code;
                    tempWallet[display_name] = tempWallet[display_name] ? (parseFloat(tempWallet[display_name]) + parseFloat(amount)).toFixed(2) : parseFloat(amount).toFixed(2);
                    walletCash += parseFloat(amount);
                }
            })
        });

        if (shippingWallet.id > 0) {
            if (parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) > 0) {
                const old_amount = parseFloat(tempWallet[shippingWallet.prefix] ? tempWallet[shippingWallet.prefix] : 0);
                tempWallet[shippingWallet.prefix] = (old_amount + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)).toFixed(shippingWallet.decimal);
            }
        } else {
            cash = parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup);
        }

        setTotalPayment({
            cash: cash - parseFloat(walletCash),
            wallet_list: tempWallet
        });
    }, [state.totalPrice, state.totalShippingPrice, state.totalWalletPrice, mallTotalShipping, shippingWallet, pickup, walletPoint]);

    const calculateTotal = userCart => {

        let totalPrice = 0;
        let totalSelected = 0;
        let totalWalletPrice = {};
        let forMultiWalletBalance = {};
        let totalShippingPrice = 0;

        _.map(userCart, (merchantItem, merchantId) => {
            let merchant_channel = _.find(selfPickUpInfo, { 'id': merchantId });
            if (merchant_channel && merchant_channel.channel == 'pickup') {
                totalShippingPrice += 0;
            } else {
                if (isMalaysiaShipping && malaysiaShippingArea === 'east') {
                    totalShippingPrice += merchantItem.shipping_fee2 > 0 ? parseFloat((merchantItem.shipping_fee2).toFixed(2)) : 0;
                } else {
                    totalShippingPrice += merchantItem.shipping_fee > 0 ? parseFloat((merchantItem.shipping_fee).toFixed(2)) : 0;
                }
            }
            _.map(merchantItem.items, (cartDetails) => {
                _.map(cartDetails, (cartItem) => {
                    if (cartItem.selected) {
                        totalSelected += parseInt(cartItem.quantity);
                        let itemPrice = parseFloat(cartItem.product.sell_price);
                        if (_.size(cartItem.product.options) > 0) {
                            _.map(cartItem.product.options, (option) => {
                                _.map(option.pricing, (pricingOption) => {
                                    if (pricingOption.id === cartItem.product_warehouse_id) {
                                        itemPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : cartItem.product.sell_price
                                    }
                                })
                            })
                        }
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        } else {
                            totalPrice += parseFloat((itemPrice * cartItem.quantity).toFixed(2));
                        }
                    }
                })
            })
        });
        setMultiWalletBalance(forMultiWalletBalance);
        setState(state => ({ ...state, totalPrice, totalSelected, totalWalletPrice, totalShippingPrice }));
    };

    const setupWalletPoint = ({ userCart , buyNow}) => {
        let tempWalletPoint = {};
        let tempWalletPointDirectPayment = {};
        _.map(userCart, (merchantItem, merchantId) => {
            _.map(merchantItem.items, (cartDetails) => {
                _.map(cartDetails, (cartItem) => {
                    if ( buyNow ) {
                        setDirectPayment(cartItem?.product?.direct_payment ?? false);
                    }
                    if (cartItem.selected) {
                        if (_.size(cartItem.product.multi_pricings) > 0 && cartItem.multi_pricing_id) {
                            // find total price
                            let productPrice = cartItem.product.sell_price;
                            if (_.size(cartItem.product.options) > 0) {
                                _.map(cartItem.product.options, (optionItem) => {
                                    _.map(optionItem.pricing, (pricingOption) => {
                                        if (pricingOption.id === cartItem.product_warehouse_id) {
                                            productPrice = parseFloat(pricingOption.price_mod) > 0 ? parseFloat(pricingOption.price_mod) : productPrice;
                                        }
                                    })
                                })
                            }
                            let totalPrice = cartItem.quantity * productPrice;
                            let sumTotal = 0;
                            let isSetCash = false;
                            // set cash wallet to productPrice
                            let productWallet = {};
                            let productWalletDirectPaymentPrice = {};
                            const selectedPricing = _.find(cartItem.product.multi_pricings, { 'id': cartItem.multi_pricing_id });
                            _.map(selectedPricing.wallet_settings, (setting, key) => {
                                let minAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.min_amount / 100) : setting.min_amount);
                                let maxAmount = parseFloat(setting.type === 'percent' ? productPrice * (setting.max_amount / 100) : setting.max_amount);
                                _.map(setting.wallet_ids, wid => {
                                    productWallet[wid] = (walletPoint[cartItem.id] && walletPoint[cartItem.id][wid]) ? walletPoint[cartItem.id][wid] : 0;
                                    productWalletDirectPaymentPrice[wid] = buyNow ? (minAmount !== maxAmount ? maxAmount : minAmount ) : (walletPoint[cartItem.id] && walletPoint[cartItem.id][wid]) ? walletPoint[cartItem.id][wid] : 0;
                                    if (wid != 'cash') {
                                        sumTotal += parseFloat(productWallet[wid]);
                                    } else {
                                        isSetCash = true;
                                    }
                                })
                            })
                            if (isSetCash) {
                                let cash = totalPrice - sumTotal;
                                productWallet['cash'] = cash > 0 ? cash : 0;
                            }
                            // set wallet point
                            tempWalletPoint[cartItem.id] = productWallet;
                            tempWalletPointDirectPayment[cartItem.id] = productWalletDirectPaymentPrice;
                        }
                    }
                })
            })
        });
        setDirectPaymentPrice(tempWalletPointDirectPayment);
        setWalletPoint(tempWalletPoint);
    }

    const submitOrder = () => {
        setCartError([]);
        let onlySelfPickUp = true;
        _.map(selfPickUpInfo, (merchant, merchantIndex) => {
            if (merchant.self_pickup === false) {
                onlySelfPickUp = false;
            }
        });
        if (!onlySelfPickUp && !state.addressId && !directPayment) {
            // show error to select address
            addAlert(t('checkout.noAddressSelected'));
        }
        else {
            setLoading(true);
            const selectedAddress = _.find(addresses, { 'id': state.addressId });
            let orderField = {
                buyer_name: buyerInfo.name,
                buyer_email: buyerInfo.email,
                buyer_mobile_code: buyerInfo.mobile_country_code,
                buyer_mobile: buyerInfo.mobile,
                remark,
                mall_shipping: mallShipping,
                recipient_source: downlineInfo.source,
                recipient_username: downlineInfo.username,
                address_id: directPayment ? 0 : (state.addressId ? state.addressId : 0),
                self_pickup: directPayment ? [] : selfPickUpInfo,
                wallet_point: directPayment ? directPaymentPrice : walletPoint ,
            }
            if (selectedAddress) {
                let orderField2 = {
                    recipient: selectedAddress.name,
                    recipient_email: buyerInfo.email,
                    mobile_code: selectedAddress.mobile_country_code,
                    mobile: selectedAddress.mobile,
                    country: selectedAddress.country,
                    zip: selectedAddress.zip,
                    state: selectedAddress.state,
                    city: selectedAddress.city,
                    address: selectedAddress.address,
                    address2: selectedAddress.address2,
                }
                orderField = { ...orderField, ...orderField2 }
            }
            if (linkReferralCode) {
                orderField = { ...orderField, referral_id: linkReferralCode }
            }
            postUrl('orders', orderField).then(response => {
                setLoading(false);
                if (response.status === 1) {
                    // redirect to payment page
                    getUrl('carts').then(cartList => {
                        let cartTotal = 0;
                        _.map(cartList.data, cartItem => {
                            _.map(cartItem.items, cartDetails => {
                                cartTotal += parseInt(_.size(cartDetails));
                            })
                        })
                        dispatch(updateCartTotal(cartTotal));
                    }).catch(error => {
                        addAlert(JSON.stringify(error.message));
                    });

                    if (response.order_cancelled) {
                        history.push({ pathname: `/order`, state: { error_msg: t('checkout.orderCancelled') } });
                    } else {
                        if (directPayment){
                            let coede = encodeURIComponent(response.data[0]['purchase_no']);
                            setLoading(true);
                            postUrl(`wallet/${coede}`).then((response) => {
                                setLoading(false);
                                if (response.status === 1) {
                                    history.push(`/paymentresult/${coede}?source=wallet`);
                                } else {
                                    addAlert(response.message);
                                }
                            }).catch(error => {
                                setLoading(false);
                                addAlert(JSON.stringify(error.message));
                            });
                        }else{
                            history.replace(`/payment/${encodeURIComponent(response.data[0]['purchase_no'])}`);
                        }
                    }
                    dispatch(updateShareLinkRef(''));
                } else {
                    setUpdateCart(!updateCart);
                    if (response.error) {
                        let newErrorField = {};
                        let displayErrorMsg = [];
                        _.map(response.error, (errorItem, errorIndex) => {
                            setCartError(response.error);
                            let ignoreList = ['buyer_name', 'buyer_mobile', 'buyer_mobile_code', 'buyer_email', 'cart', 'remark', 'recipient_username'];
                            if(!_.find(ignoreList, function(index){ return errorIndex.includes(index); })){
                                newErrorField[errorIndex] = errorItem[0]
                                displayErrorMsg.push(errorItem[0]);
                            }
                        })
                        setErrorField(newErrorField);
                        addAlert(_.size(displayErrorMsg) > 0 ? _.map(displayErrorMsg).join(' ') : t('checkout.placeOrderError'));
                    } else if (response.message) {
                        addAlert(JSON.stringify(response.message));
                    }
                }
            }).catch(error => {
                setLoading(false);

                addAlert(JSON.stringify(error.message));
            });
        }
    }

    const noCartDisplay = () => {
        return (
            <Box minHeight={100} width={"100%"} display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center" style={{marginBottom: 20}}>
                {
                    state.totalSelected === null ?
                        <>
                            <CircularProgress disableShrink />
                            <Typography variant="caption">{t('general.fetchingData')}</Typography>
                        </>
                        :
                        <>
                            <Typography variant="caption">{t('checkout.noItem')}</Typography>
                            <Link underline='none' to={`/cart`} component={RouterLink}>
                                <Button variant="contained" color="secondary">
                                    {t('button.goCart')}
                                </Button>
                            </Link>
                        </>
                }
            </Box>
        )
    }

    const paymentDisplay = () => {
        return (
            <div className='w-full'>
                {/* Total Product Price */}
                <Grid container>
                    <Grid item xs={6}>
                        <p className='fs-content cl-label'>{state.totalSelected}{t('checkout.productPayment')}：</p>
                    </Grid>
                    <Grid item xs={6} className='txt-right'>
                        {
                            (parseFloat(state.totalPrice).toFixed(2) == 0) && (_.size(state.totalWalletPrice) == 0)
                            &&
                            <p className='fs-content cl-label'>{i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} 0.00</p>
                        }
                        {
                            parseFloat(state.totalPrice).toFixed(2) > 0
                                ?
                                <p className='fs-content cl-label'><NumberFormat value={(parseFloat(state.totalPrice) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></p>
                                : null
                        }
                        {
                            _.size(state.totalWalletPrice) > 0 ?
                                _.map(state.totalWalletPrice, (walletTotal, walletType) => {
                                    return (
                                        <div key={walletType}>
                                            <p className='fs-content cl-label'>
                                                <NumberFormat value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={` ${walletType} `} />
                                            </p>
                                        </div>
                                    )
                                })
                                : null
                        }
                    </Grid>
                </Grid>
                {/* Total Shipping Fee */}
                <Grid container>
                    <Grid item xs={6}>
                        <p className='fs-content cl-label'>{t('checkout.shippingFee')}：</p>{/* {state.totalSelected}  */}
                    </Grid>
                    <Grid item xs={6} className='txt-right'>
                        {
                            fetchingMallShipping
                                ?
                                <CircularProgress disableShrink size="1.5rem" />
                                :
                                <p className='fs-content cl-label'>
                                    {
                                        (parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) > 0
                                        ?
                                        <NumberFormat value={((parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                        :
                                        t('order.freeShipping')
                                    }
                                </p>
                        }
                    </Grid>
                </Grid>
                {/* Total Payment */}
                <Grid container className='p-t-10'>
                    <Grid item xs={6}>
                        <p className='fs-16 cl-theme'><b>{t('checkout.totalPayment')}：</b></p>
                    </Grid>
                    <Grid item xs={6} className='txt-right'>
                        {
                            fetchingMallShipping
                                ?
                                <CircularProgress disableShrink size="1.5rem" />
                                :
                                parseFloat(state.totalPrice).toFixed(2) > 0 ?
                                    <p className='fs-16 cl-theme'><b><NumberFormat value={((parseFloat(state.totalPrice) + parseFloat(state.totalShippingPrice) + parseFloat(mallTotalShipping) - parseFloat(pickup)) * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} /></b></p>
                                    : <p className='fs-16 cl-theme'><b>{i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} 0.00</b></p>
                        }
                    </Grid>
                </Grid>
                {
                    ((parseFloat(totalPayment.cash).toFixed(2)) > 0) || (_.size(totalPayment.wallet_list) > 0)
                        ?
                        <Grid container className='p-b-50'>
                            <Grid item xs={6}>
                                <p className='fs-content cl-label'>{t('payment.paymentBreakdown')}</p>
                            </Grid>
                            <Grid item xs={6} className='txt-right'>
                                <div className='lh-10'>
                                    {
                                        fetchingMallShipping
                                            ?
                                            <CircularProgress disableShrink size="1.5rem" />
                                            :
                                            !directPayment && ((parseFloat(totalPayment.cash).toFixed(2)) > 0)
                                                ?
                                                <NumberFormat className={`cl-label ${styles.priceStyle}`} value={parseFloat(totalPayment.cash * currencyRate[`${currency}`]).toFixed(2)} decimalScale={2} displayType={'text'} thousandSeparator={true} prefix={`${i18n.language === 'cn' ? (currency_code[1] ? currency_code[1] : currency_code[0]) : currency_code[0]} `} />
                                                : null
                                    }
                                </div>
                                {_.size(totalPayment.wallet_list) > 0 &&
                                    _.map(totalPayment.wallet_list, (walletTotal, walletType) => {
                                        let walletBalance = 0;
                                        let decimal = 2;
                                        if (multiWalletBalance[walletType]) {
                                            _.map(multiWalletBalance[walletType], (walletId) => {
                                                let _wallet = _.find(wallets, { 'id': walletId });
                                                if (_wallet) {
                                                    walletBalance = (parseFloat(walletBalance) + parseFloat(_wallet.balance));
                                                    // decimal = _wallet.decimal;
                                                }
                                            })
                                            walletBalance = parseFloat(walletBalance).toFixed(decimal);
                                        } else {
                                            _.map(wallets, (details) => {
                                                let name = _.split(details.name, "|");
                                                let type = i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0];
                                                if (walletType === type) {
                                                    walletBalance = details.balance;
                                                }
                                            })
                                        }
                                        return (
                                            <Box key={walletType}>
                                                <p className='fs-content'>
                                                    <NumberFormat className={`cl-label ${styles.priceStyle}`} value={walletTotal} displayType={'text'} thousandSeparator={true} prefix={` ${walletType} `} />
                                                </p>
                                            </Box>
                                        )
                                    })
                                }
                                {
                                    directPayment && 
                                    _.map( directPaymentPrice , ( dpp_data , dpp_key ) => {
                                        return (
                                            <>
                                                {
                                                    _.map(dpp_data, (data , key) => {
                                                        let wallet_data = _.find(wallets, { 'id': parseInt(key) })
                                                        if ( wallet_data ) {
                                                            return (
                                                                <Box key={key}>
                                                                    <p className='fs-content'>
                                                                        <NumberFormat className={`cl-label ${styles.priceStyle}`} value={parseFloat(data).toFixed(wallet_data.decimal)} displayType={'text'} thousandSeparator={true} prefix={` ${wallet_data?.code} `} />
                                                                    </p>
                                                                </Box>
                                                            )
                                                        }
                                                        
                                                    })
                                                }
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        :
                        null
                }
            </div>
        )
    }

    const DirectPaymentLayout = () => {
        return (
            <div>
                {/* Buyer Card */}
                <CheckoutBuyerCard buyerInfo={buyerInfo} setBuyerInfo={setBuyerInfo} downlineInfo={downlineInfo} setDownlineInfo={setDownlineInfo} cartErrors={cartErrors} />
                {/* Shipping Address */}
                <div style={{ marginTop: 20 }}>
                    {/* <CheckoutAddressCard state={state} setState={setState} addresses={addresses} setAddress={setAddress} fetchingMallShipping={fetchingMallShipping} mallShippingRefresh={mallShippingRefresh} setMallShippingRefresh={setMallShippingRefresh} /> */}
                </div>
                {/* Seller Listing */}
                <div className='w-full' style={{ marginTop: 20 }}>
                    <div className='w-full'>
                        {
                            state.totalSelected && state.totalSelected != null ?
                                <DirectCheckoutSellerCard 
                                    cart={cart} 
                                    mallShipping={mallShipping} 
                                    fetchingMallShipping={fetchingMallShipping} 
                                    walletPoint={walletPoint} 
                                    setWalletPoint={setWalletPoint} 
                                    currency_code={currency_code} 
                                    currency={currency} 
                                    currencyRate={currencyRate} 
                                    currencyData={currencyData} 
                                    remark={remark} 
                                    setRemark={setRemark} 
                                    selfPickUpInfo={selfPickUpInfo} 
                                    setSelfPickupInfo={setSelfPickupInfo} 
                                    cartErrors={cartErrors} 
                                    mallFee={mallFee} 
                                    setMallFee={setMallFee} 
                                    setMallTotalShipping={setMallTotalShipping} 
                                    pickup={pickup} setPickUp={setPickUp} 
                                    setDisabledSubmit={setDisabledSubmit} 
                                    shippingWallet={shippingWallet} 
                                    wallets={wallets} 
                                    isMalaysiaShipping={isMalaysiaShipping} 
                                    malaysiaShippingArea={malaysiaShippingArea} 
                                    state={state} 
                                    addresses={addresses} 
                                    setFetchingMallShipping={setFetchingMallShipping} 
                                    setIsMalaysiaShipping={setIsMalaysiaShipping} 
                                    setMalaysiaShippingArea={setMalaysiaShippingArea} 
                                    mallShippingRefresh={mallShippingRefresh} 
                                    setShippingWallet={setShippingWallet} 
                                    setMallShipping={setMallShipping} 
                                    setCartError={setCartError} 
                                    directPayment={directPayment}
                                />
                                :
                                noCartDisplay()
                        }
                    </div>
                </div>
                {/* Payment */}
                {paymentDisplay()}
            </div>
        )
    }
    
    const NormalLayout = () => {
        return (
            <div>
                {/* Buyer Card */}
                <CheckoutBuyerCard buyerInfo={buyerInfo} setBuyerInfo={setBuyerInfo} downlineInfo={downlineInfo} setDownlineInfo={setDownlineInfo} cartErrors={cartErrors} />
                {/* Shipping Address */}
                <div style={{ marginTop: 20 }}>
                    <CheckoutAddressCard state={state} setState={setState} addresses={addresses} setAddress={setAddress} fetchingMallShipping={fetchingMallShipping} mallShippingRefresh={mallShippingRefresh} setMallShippingRefresh={setMallShippingRefresh} />
                </div>
                {/* Seller Listing */}
                <div className='w-full' style={{ marginTop: 20 }}>
                    <div className='w-full'>
                        {
                            state.totalSelected && state.totalSelected != null ?
                                <CheckoutSellerCard cart={cart} mallShipping={mallShipping} fetchingMallShipping={fetchingMallShipping} walletPoint={walletPoint} setWalletPoint={setWalletPoint} currency_code={currency_code} currency={currency} currencyRate={currencyRate} currencyData={currencyData} remark={remark} setRemark={setRemark} selfPickUpInfo={selfPickUpInfo} setSelfPickupInfo={setSelfPickupInfo} cartErrors={cartErrors} mallFee={mallFee} setMallFee={setMallFee} setMallTotalShipping={setMallTotalShipping} pickup={pickup} setPickUp={setPickUp} setDisabledSubmit={setDisabledSubmit} shippingWallet={shippingWallet} wallets={wallets} isMalaysiaShipping={isMalaysiaShipping} malaysiaShippingArea={malaysiaShippingArea} state={state} addresses={addresses} setFetchingMallShipping={setFetchingMallShipping} setIsMalaysiaShipping={setIsMalaysiaShipping} setMalaysiaShippingArea={setMalaysiaShippingArea} mallShippingRefresh={mallShippingRefresh} setShippingWallet={setShippingWallet} setMallShipping={setMallShipping} setCartError={setCartError} />
                                :
                                noCartDisplay()
                        }
                    </div>
                </div>
                {/* Payment */}
                {paymentDisplay()}
            </div>
        )
    }

    return (
        <div>
            <TitleBar backgroundColor="bg-theme" back currencyButton />
            <div className='bg-theme p-b-100 header-radius'>
                <Container>
                    <p className='txt-center fs-18 clwhite p-t-30'>{t('title.checkout')}</p>
                    <div className='subtitle-box'>
                        <p className='cl-label fs-14 lh-11 txt-center'>{t('checkout.subtitle')}</p>
                    </div>
                </Container>
            </div>
            <Container>
                {
                    directPayment && buyNow
                    ? DirectPaymentLayout() 
                    : NormalLayout()
                }
                {/* {NormalLayout()} */}
            </Container>
            {/* Footer */}
            <Box className={styles.stickyFooter} style={{ zIndex: 1299, minHeight: 68 }}>
                {/* Error Display */}
                {_.size(cartErrors[`cart`]) ?
                    <div className='p-t-5 p-l-30'>
                        {
                            _.map(cartErrors[`cart`], (errMsg, key) => {
                                return (
                                    <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                                )
                            })
                        }
                    </div>
                    :
                    null
                }
                {errorField &&
                    <div className='p-t-5 p-l-30'>
                        {
                            _.map(errorField, (errMsg, key) => (
                                <p key={key} className='cl-error fs-remark'>{errMsg}</p>
                            ))
                        }
                    </div>
                }
                <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: '0 20px 20px', height: '100%' }}>
                    <Button variant="contained" fullWidth size="large" style={{ backgroundColor: state.totalSelected > 0 ? (disabledSubmit ? '#e0e2e2' : '#18267C') : '#18267C', color: state.totalSelected > 0 ? (disabledSubmit ? '#8e8d8d' : '#fff') : '#fff' }} onClick={submitOrder} disabled={state.totalSelected > 0 ? (disabledSubmit ? true : false) : true}>
                        { directPayment ? t('payment.systemWallet') : t('button.placeOrder') }
                    </Button>
                </Box>
            </Box>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    priceStyle: {
        fontSize: 14,
        // fontWeight: 'bold'
    },
    stickyFooter: {
        width: '100%',
        maxWidth: 420,
        position: 'fixed',
        // Top: 'calc(5% + 60px)',
        bottom: 0,
        backgroundColor: '#f2f2f2',
        // boxShadow: '0 -5px 11px 0 #0000001a',
        zIndex: 1,
        minHeight: 70,
    },
}));