import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Button, Dialog, DialogContent, Slide, Typography, DialogActions, DialogTitle, IconButton } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { IoStorefrontOutline } from "react-icons/io5";

import { currencyFormat } from '@helper/Tools';
import { ArrowForwardIos } from '@material-ui/icons';

export default function WalletDetailDialog(props) {
    const { dialogOpen, closeDialog, dialogInfo } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [expireDialogOpen, setExpireDialogOpen] = useState(false);
    const [expireDialogInfo, setExpireDialogInfo] = useState({});

    const bgColors = [
        'gradient-purple',
        'gradient-blue',
    ];

    return (
        <Dialog
            open={dialogOpen}
            fullWidth
            style={{ width: 547, maxWidth: '100%', margin: '0 auto' }}
            onClose={closeDialog}
            TransitionComponent={Transition}
            disableScrollLock
        >
            <DialogTitle style={{ padding: '30px 35px 0px' }}>
                <Typography><b>{t('transfer.dialogSub')} : </b></Typography>
            </DialogTitle>
            <DialogContent style={{ paddingTop: 0, paddingLeft: 30, paddingRight: 30 }}>
                {
                    _.map(dialogInfo, (detail, key) => {
                        let color_index = key % bgColors.length;
                        return (
                            <div key={key} className='p-t-10'>
                                <div className='p-lr-10 p-b-5'>
                                    <div className='flex-l-m'>
                                        {/* <Typography>{t('transfer.merchant')} : </Typography> */}
                                        <div className={`${bgColors[color_index]} bor30 flex-c-m`} style={{ width: 45, height: 45, aspectRatio: '1/1' }}>
                                            <div className='bor30 flex-c-m' style={{ width: 42, height: 42, aspectRatio: '1/1', background: '#fff' }}>
                                                <div className={`bor30 p-all-5 ${bgColors[color_index]} flex-c-m`} style={{ width: 37, height: 37, aspectRatio: '1/1' }}>
                                                    <IoStorefrontOutline size={25} color='#fff' />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex-col-l'>
                                            <Typography variant={detail?.company_name?.length > 20 ? 'body1' : 'h6'} className='p-l-10' style={{ lineHeight: 1.1 }}><b>{detail.merchant_company_id != null ? `${detail.company_name}` : 'GLOBAL'}</b></Typography>
                                            <Typography className='p-l-10' style={{ lineHeight: 1.0, fontSize: 13 }}>{detail.merchant_company_id != null ? `(@${detail.slug})` : null}</Typography>
                                        </div>
                                    </div>
                                    <Typography variant={detail.balance > 999999999 ? 'h5' : 'h4'} className={`${bgColors[color_index]}-text`}><b>{currencyFormat((detail.balance * 100) / 100)}</b></Typography>
                                    {
                                        _.size(detail.validities) > 0 &&
                                        <div className='flex-sb-m'>
                                            <div>
                                             
                                                <p className='fs-10'>{t('transfer.point',{p : detail.validities[0].balance})}</p>
                                                <p className='fs-10'>{t('transfer.exipire',{date : detail.validities[0].end_date})}</p>
                                            </div>
                                            <div className='flex-r-m' style={{ cursor: 'pointer' }} onClick={() => {setExpireDialogOpen(true); setExpireDialogInfo(detail.validities)}}>
                                                <p className='fs-10'>{t('transfer.details')}</p>
                                                <IconButton style={{padding:'0px'}}>
                                                    <ArrowForwardIos style={{ fontSize: 15, color:'#696d84' }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {
                                    key !== (dialogInfo?.length - 1) &&
                                    <div className='divider-white w-full m-tb-5' style={{ background: '#dbdbdb', height: 4 }} />
                                }
                            </div>
                        )
                    })
                }
            </DialogContent>
            <DialogActions style={{ paddingLeft: 35, paddingRight: 35 }}>
                <Button fullWidth variant="contained" classes={{ root: classes.buttonRoot }} className='gradient-blue' onClick={closeDialog}>
                    {t('transaction.done')}
                </Button>
            </DialogActions>
            <Dialog
                open={expireDialogOpen}
                fullWidth
                style={{ width: 547, maxWidth: '100%', margin: '0 auto' }}
                onClose={()=> {setExpireDialogOpen(false); setExpireDialogInfo({})}}
                TransitionComponent={Transition}
                disableScrollLock
            >
                <DialogTitle style={{ padding: '30px 35px 0px' }}>
                    <Typography><b>{t('transfer.exipireDialogSub')} : </b></Typography>
                </DialogTitle>
                <DialogContent style={{ paddingTop: 0, paddingLeft: 35, paddingRight: 30 }}>
                    {
                        _.map(expireDialogInfo, (validity, key) => {
                            return (
                                <div key={key}>
                                    <p className='fs-12'>{t('transfer.point',{p : validity.balance})} {t('transfer.exipire',{date : validity.end_date})} </p>
                                </div>

                            )
                        })
                    }
                </DialogContent>
                <DialogActions style={{ paddingLeft: 35, paddingRight: 35 }}>
                    <Button fullWidth variant="contained" classes={{ root: classes.buttonRoot }} className='gradient-blue' onClick={()=> {setExpireDialogOpen(false); setExpireDialogInfo({})}}>
                        {t('transaction.done')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    )
}

const Transition = forwardRef((props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    buttonRoot: {
        // backgroundColor: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: 25,
        // marginTop: 15,
        marginBottom: 30,
        // marginLeft: 20,
        // marginRight: 20
    },
}));